import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function SucessSvg(props: SvgProps) {
  const { width, height } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 220 220"
    >
      <circle
        cx="110"
        cy="110"
        r="107.5"
        fill="#1F2AD1"
        fillOpacity="0.3"
        stroke="#1F2AD1"
        strokeWidth="5"
      />
      <path
        stroke="#1F2AD1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="21"
        d="M56.28 102.513l35.547 61.527 76.172-101.08"
      />
    </svg>
  )
}

export default SucessSvg

SucessSvg.defaultProps = {
  width: 220,
  height: 220,
}
