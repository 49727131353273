//modules
import React, { memo, useRef, useState } from 'react'
// @ts-ignore
import * as Scroll from 'react-scroll'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import { useTranslation } from 'react-i18next'
//constants
import { position } from '../../constants'
//components
import {
  About,
  Approach,
  Home,
  NavLinks,
  Project,
  Header,
  Service,
  Technologies,
} from '../index'
import Footer from '../Footer'
import Menu from '../../components/burgerMenu'
import HeaderMob from './components/headerMob'
//style
import './style.css'
import { MainScreen } from './style'
import { useMediaQuery } from 'react-responsive'
import OurTeam from '../OurTeam'
import { ScreenProps } from '../../interfaces/ScreenProps'

function MainPage({
  setScreen,
  index,
  setIndex,
  language,
  activeExp,
  setActiveExp,
  customSwapExp,
  headerClick,
  menuClick,
}: ScreenProps) {
  const { t } = useTranslation()
  const [menuActive, setMenuActive] = useState<boolean>(false)
  const LinkArr = t('linkArr', { returnObjects: true })
  const isLaptopWidth = useMediaQuery({ minDeviceWidth: 500 })
  const isBigLaptop = useMediaQuery({ minDeviceWidth: 999 })
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1144px)' })
  const isLittleHeight = useMediaQuery({ maxHeight: 800 })
  const isBigHeight = useMediaQuery({ minHeight: 1079 })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1900px)' })
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1400px)' })
  const isLandscape = useMediaQuery({ orientation: 'landscape' })
  const [active, setActive] = useState<number>(0)
  const customSlider = useRef<any>()

  let scroller = Scroll.scroller
  let Element = Scroll.Element

  function NavigateToTop() {
    let newIndex = index - 1
    if (newIndex <= 0) {
      newIndex = 0
    }
    setIndex(newIndex)
    scroller.scrollTo(position[newIndex], {
      duration: 600,
      smooth: 'ease',
    })
  }

  function NavigateToBottom() {
    let newIndex = index + 1
    if (newIndex >= 7) {
      newIndex = 7
    }
    setIndex(newIndex)
    scroller.scrollTo(position[newIndex], {
      duration: 600,
      smooth: 'ease',
    })
  }
  return (
    <ReactScrollWheelHandler
      preventScroll={true}
      disableSwipe={true}
      disableSwipeWithMouse={true}
      upHandler={() => (isMobileScreen ? null : NavigateToTop())}
      downHandler={() => (isMobileScreen ? null : NavigateToBottom())}
    >
      <MainScreen>
        <div className={'headerContainer'}>
          {isMobileScreen ? (
            <div>
              <HeaderMob
                isLaptopWidth={isLaptopWidth}
                isLandscape={isLandscape}
              />
              <div
                className={
                  isLandscape ? 'MainPageBurgerLand' : 'MainPageBurger'
                }
              >
                <div className={'burgerContainer'}>
                  <div
                    className={'burgerBtn'}
                    onClick={() => setMenuActive(!menuActive)}
                  >
                    <span />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Header
                headerClick={headerClick}
                isMobileScreen={isMobileScreen}
                language={language}
                isLandscape={isLandscape}
              />
            </div>
          )}
        </div>
        <div className={'MainDiv'}>
          <div
            className={
              !isMobileScreen && !isLaptopWidth && !isLandscape
                ? 'outDiv'
                : isMobileScreen &&
                  !isLaptopWidth &&
                  isBigLaptop &&
                  !isLandscape
                ? 'outDiv'
                : isMobileScreen && isLandscape
                ? 'outDivLand'
                : 'outDivLap'
            }
          >
            <Element
              name={'goHome'}
              className={
                isMobileScreen && !isLaptopWidth
                  ? 'mainDivMob'
                  : isMobileScreen &&
                    isLaptopWidth &&
                    !isBigLaptop &&
                    !isLandscape
                  ? 'mainDivHomeLap'
                  : isMobileScreen && isBigLaptop && !isLandscape
                  ? 'mainDivHomeBLap'
                  : isLandscape && isMobileScreen
                  ? 'mainDivHomeLandLap'
                  : 'homeDiv'
              }
              id={'home'}
            >
              <Home
                customSlider={customSlider}
                isLandscape={isLandscape}
                isMobileScreen={isMobileScreen}
                isBigLaptop={isBigLaptop}
                isLaptopWidth={isLaptopWidth}
                setIndex={setIndex}
                setActiveSlide={setActive}
                language={language}
              />
            </Element>
            <Element
              name={'toExp'}
              className={
                isMobileScreen && !isLaptopWidth && !isLandscape
                  ? 'mainAboutDivMob'
                  : isMobileScreen &&
                    isLaptopWidth &&
                    !isBigLaptop &&
                    !isLandscape
                  ? 'AboutDivLap'
                  : isMobileScreen && isBigLaptop && !isLandscape
                  ? 'AboutDivBLap'
                  : isMobileScreen && isLandscape
                  ? 'AboutDivLand'
                  : 'aboutDiv'
              }
              id={'experience'}
            >
              <About
                activeExp={activeExp}
                setActiveExp={setActiveExp}
                isMobileScreen={isMobileScreen}
                isBigLaptop={isBigLaptop}
                isLaptopWidth={isLaptopWidth}
                language={language}
                isLandscape={isLandscape}
                setScreen={setScreen}
                customSwapExp={customSwapExp}
              />
            </Element>
            <Element
              name={'toApproach'}
              className={
                isMobileScreen && !isLaptopWidth && !isLandscape
                  ? 'mainPageMob'
                  : isMobileScreen &&
                    isLaptopWidth &&
                    !isBigLaptop &&
                    !isLandscape
                  ? 'mainDivPageLap'
                  : isMobileScreen && isBigLaptop && !isLandscape
                  ? 'mainDivHomeBLap'
                  : isMobileScreen && isLandscape
                  ? 'mainDivHomeLand'
                  : 'mainPageDiv'
              }
              id={'approach'}
            >
              <Approach
                isMobileScreen={isMobileScreen}
                isLaptopWidth={isLaptopWidth}
                isBigLaptop={isBigLaptop}
                isLittleHeight={isLittleHeight}
                isBigHeight={isBigHeight}
                isLandscape={isLandscape}
              />
            </Element>
            <Element
              name={'toService'}
              className={
                isMobileScreen && !isLaptopWidth && !isLandscape
                  ? 'mainServiceMob'
                  : isMobileScreen &&
                    isLaptopWidth &&
                    !isBigLaptop &&
                    !isLandscape
                  ? 'serviceDivLap'
                  : isMobileScreen && isBigLaptop && !isLandscape
                  ? 'mainDivHomeBLap'
                  : isLandscape && isMobileScreen
                  ? 'mainDivServiceLand'
                  : 'approachDiv'
              }
              id={'service'}
            >
              <Service
                active={active}
                setActive={setActive}
                customSlider={customSlider}
                isMobileScreen={isMobileScreen}
                isBigLaptop={isBigLaptop}
                isBigScreen={isBigScreen}
                isLaptopWidth={isLaptopWidth}
                isSmallScreen={isSmallScreen}
                isLandscape={isLandscape}
              />
            </Element>
            <Element
              name={'toTeam'}
              className={
                isMobileScreen && !isLaptopWidth && !isLandscape
                  ? 'mainTeamMob'
                  : isMobileScreen &&
                    isLaptopWidth &&
                    !isBigLaptop &&
                    !isLandscape
                  ? 'mainDivProjectLap'
                  : isMobileScreen && isBigLaptop && !isLandscape
                  ? 'mainDivHomeBLap'
                  : isMobileScreen && isLandscape
                  ? 'mainDivProjectLand'
                  : 'projectDiv'
              }
              id={'ourTeam'}
            >
              <OurTeam
                isLandscape={isLandscape}
                isMobileScreen={isMobileScreen}
                isLaptopWidth={isLaptopWidth}
                language={language}
              />
            </Element>
            <Element
              name={'toTechno'}
              className={
                isMobileScreen && !isLaptopWidth && !isLandscape
                  ? 'mainTechnoMob'
                  : isMobileScreen &&
                    isLaptopWidth &&
                    !isBigLaptop &&
                    !isLandscape
                  ? 'mainDivServiceLap'
                  : isMobileScreen && isBigLaptop && !isLandscape
                  ? 'mainDivServiceBLap'
                  : isMobileScreen && isLandscape
                  ? 'mainDivServiceLand'
                  : 'serviceDiv'
              }
              id={'technology'}
            >
              <Technologies
                isMobileScreen={isMobileScreen}
                isBigLaptop={isBigLaptop}
                isBigScreen={isBigScreen}
                isLaptopWidth={isLaptopWidth}
                isSmallScreen={isSmallScreen}
                isLandscape={isLandscape}
              />
            </Element>
            <Element
              name={'toProject'}
              className={
                isMobileScreen && !isLaptopWidth && !isLandscape
                  ? 'mainProjectMob'
                  : isMobileScreen &&
                    isLaptopWidth &&
                    !isBigLaptop &&
                    !isLandscape
                  ? 'mainDivLap'
                  : isMobileScreen && isBigLaptop && !isLandscape
                  ? 'mainDivHomeBLap'
                  : isMobileScreen && isLandscape
                  ? 'mainDivExpLand'
                  : 'experienceDiv'
              }
              id={'getProject'}
            >
              <Project
                language={language}
                isMobileScreen={isMobileScreen}
                isLaptopWidth={isLaptopWidth}
                isBigLaptop={isBigLaptop}
                isLandscape={isLandscape}
                setIndex={setIndex}
                index={index}
              />
            </Element>
          </div>
          {!isMobileScreen ? (
            <div className={'navContainer'}>
              <NavLinks setIndex={setIndex} />
            </div>
          ) : null}
        </div>
        <Element name={'footer'} id={'Footer'}>
          <Footer
            setIndex={setIndex}
            isLandscape={isLandscape}
            isMobileScreen={isMobileScreen}
          />
        </Element>
        {isMobileScreen ? (
          <Menu
            setIndex={setIndex}
            isLandscape={isLandscape}
            language={language}
            active={menuActive}
            setActive={setMenuActive}
            items={LinkArr}
            isLaptopWidth={isLaptopWidth}
            isMobileScreen={isMobileScreen}
            menuClick={menuClick}
          />
        ) : null}
      </MainScreen>
    </ReactScrollWheelHandler>
  )
}

export default memo(MainPage)
