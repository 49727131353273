// modules
import React, { forwardRef } from 'react'
import { motion } from 'framer-motion'
// interfaces
import { BtnHomeProps } from '../../interfaces/BtnHomeProps'
// styles
import './style.css'

export const Button = (props: BtnHomeProps) => {
  const { className, onClick, text } = props
  return (
    <button className={className} onClick={onClick}>
      {text}
    </button>
  )
}

Button.defaultProps = {
  className: 'BtnStyle',
}
