// modules
import React, { Suspense } from 'react'
// Screens
import './index.css'
import MainComponent from './screens/MainComponent'

function App() {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <React.StrictMode>
        <MainComponent />
      </React.StrictMode>
    </Suspense>
  )
}

export default App
