//modules
import React from 'react'
// @ts-ignore
import * as Scroll from 'react-scroll'
//components
import Switch from '../../../../components/switch/switch'
// assets
import { LogoSvg } from '../../../../assets/svg'
//interface
import { ScreenProps } from '../../../../interfaces/ScreenProps'

function Header({
  isMobileScreen,
  language,
  isLandscape,
  headerClick,
}: ScreenProps) {
  let Link = Scroll.Link

  return (
    <div className={'logoContainer'}>
      <div className={'logoPosition'}>
        <div className={'switcherContainer'}>
          <Switch
            isMobileScreen={isMobileScreen}
            language={language}
            isLandscape={isLandscape}
          />
        </div>
        <div className={'imgDiv'}>
          <Link to={'home'} onClick={headerClick} smooth={true} duration={600}>
            <LogoSvg />
          </Link>
        </div>
      </div>
    </div>
  )
}
export default Header
