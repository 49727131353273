//modules
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
//components
import { Header } from '../../../../index'
import HeaderMob from '../../../../MainPage/components/headerMob'
import Menu from '../../../../../components/burgerMenu'
import Switch from '../../../../../components/switch/switch'
import ArrowBack from '../../../../../assets/svg/ArrowBack'
//style
import '../../../Fitness/style.css'
//interface
import { ChildTitleProps } from '../../../../../interfaces/ChildTitleProps'

const ChildTitle = ({
  language,
  title,
  text,
  OutProjectArr,
  image,
  children,
  onClick,
  processTitle,
  isMobileScreen,
  headerClick,
  isLaptopWidth,
}: ChildTitleProps) => {
  const { t } = useTranslation()
  const [menuActive, setMenuActive] = useState(false)

  const LinkArr = t('linkArr', { returnObjects: true })

  return (
    <div className={isMobileScreen ? 'mainFitnessMob' : 'mainFitness'}>
      <div className={'headerCase'}>
        {isMobileScreen ? (
          <div>
            <div className={isLaptopWidth ? 'switcherCaseLap' : 'switcherCase'}>
              <Switch
                isMobileScreen={isMobileScreen}
                language={language}
                isLaptopWidth={isLaptopWidth}
              />
            </div>
            <HeaderMob
              headerClick={headerClick}
              isLaptopWidth={isLaptopWidth}
            />
          </div>
        ) : (
          <div>
            <Header
              isMobileScreen={isMobileScreen}
              language={language}
              headerClick={headerClick}
            />
          </div>
        )}
      </div>
      <div className={'caseTitleChildContainer'}>
        <div
          className={isMobileScreen ? 'childContainerMob' : 'childContainer'}
        >
          <div
            className={
              isMobileScreen ? 'arrowBtnContainerMob' : 'arrowBtnContainer'
            }
          >
            <div
              className={
                isMobileScreen && !isLaptopWidth
                  ? 'arrowBackMob'
                  : isMobileScreen && isLaptopWidth
                  ? 'arrowBackLap'
                  : 'arrowBack'
              }
              onClick={onClick}
            >
              <ArrowBack
                className={'aboutArrow4'}
                width={isMobileScreen ? 40 : 20}
                height={isMobileScreen ? 60 : 40}
              />
              <ArrowBack
                className={'aboutArrow3'}
                width={isMobileScreen ? 40 : 20}
                height={isMobileScreen ? 60 : 40}
              />
              <ArrowBack
                className={'aboutArrow2'}
                width={isMobileScreen ? 40 : 20}
                height={isMobileScreen ? 60 : 40}
              />
              <ArrowBack
                className={'aboutArrow1'}
                width={isMobileScreen ? 40 : 20}
                height={isMobileScreen ? 60 : 40}
              />
            </div>
          </div>
          <p
            className={isMobileScreen ? 'caseChildTitleMob' : 'caseChildTitle'}
          >
            {title}
          </p>
        </div>
        <div className={'childTextContainer'}>
          <p className={isMobileScreen ? 'caseChildTextMob' : 'caseChildText'}>
            {text}
          </p>
        </div>
      </div>
      {children}
      <div className={'outProject'}>
        <div
          className={!isMobileScreen ? 'outProjectTitle' : 'outProjectTitleMob'}
        >
          <p className={isMobileScreen ? 'OutTitleMob' : 'OutTitle'}>
            {processTitle}
          </p>
        </div>
        <div
          className={
            !image && !isMobileScreen
              ? 'withoutImg'
              : !image && isMobileScreen
              ? 'withoutImgMob'
              : 'outProjectContent'
          }
        >
          <ul
            className={
              !image && !isMobileScreen
                ? 'firewallText'
                : !image && isMobileScreen
                ? 'firewallTextMob'
                : 'outText'
            }
          >
            {OutProjectArr.map((item, index) => {
              return <li key={index}>{item.text}</li>
            })}
          </ul>
          {!image ? null : <img src={image} alt="img" />}
        </div>
      </div>
      <div
        className={
          isMobileScreen ? 'FitnessFooterInfoMob' : 'FitnessFooterInfo'
        }
      >
        <div>
          <p className={isMobileScreen ? 'FitnessInfoMob' : 'FitnessInfo'}>
            {t('NDA')}
          </p>
        </div>
      </div>
      <Menu
        language={language}
        active={menuActive}
        setActive={setMenuActive}
        items={LinkArr}
        isMobileScreen={isMobileScreen}
      />
    </div>
  )
}

export default ChildTitle
