// modules
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import { Link } from 'react-scroll'
// assets
import SucessSvg from '../../assets/svg/sucessSvg'
// styles
import './style.css'
// stores
import { CheckFull, HandleChange } from '../../store/popUp/logic'
// constants
import { geolocationDB } from '../../constants'
//interface
import { ScreenProps } from '../../interfaces/ScreenProps'
//type
import { InputValueTypes } from '../../types/InputValueTypes'

const InputMobScreen = (props: ScreenProps) => {
  const {
    isBigLaptop,
    isLaptopWidth,
    laptopNav,
    BLaptopNav,
    MobNav,
    isLandscape,
    landscapeNav,
    sucessMassageMob,
  } = props
  const [inputValue, setInputValue] = useState<InputValueTypes>({
    name: '',
    email: '',
    aboutProject: '',
  })
  const [errName, setErrName] = useState<boolean>(false)
  const [errEmail, setErrEmail] = useState<boolean>(false)
  const [errValue, setErrValue] = useState<boolean>(false)
  const [details, setDetails] = useState<any>(null)
  const [show, setShow] = useState<any>(false)
  const { t } = useTranslation()

  useEffect(() => {
    fetch(geolocationDB)
      .then((response) => response.json())
      .then((data) => setDetails(data))
      .catch((rejected) => {
        console.log('error !', rejected)
      })
  }, [])

  const handleChange = (e: any) => {
    HandleChange(e, setInputValue, inputValue)
  }

  const checkInputs = () => {
    CheckFull(
      inputValue.email,
      inputValue.name,
      inputValue.aboutProject,
      setErrEmail,
      setErrName,
      setErrValue,
      details.country_name,
      details.city,
      details.IPv4,
      setShow
    )
  }

  return (
    <div className={'inputScreenMob'}>
      {!show ? (
        <div
          className={
            isLaptopWidth && !isBigLaptop && !isLandscape
              ? laptopNav
              : isBigLaptop && !isLandscape
              ? BLaptopNav
              : isLandscape
              ? landscapeNav
              : MobNav
          }
        >
          <div>
            <div className={isLaptopWidth ? 'descContainerLap' : ''}>
              <p
                className={
                  !isLaptopWidth && !isLandscape
                    ? 'InputDescription'
                    : isLandscape
                    ? 'InputDescLand'
                    : 'InputDescLap'
                }
              >
                {/*@ts-ignore*/}
                {t('inputs.text')}
              </p>
            </div>
            <form className={!isLandscape ? 'InputForms' : 'InputLand'}>
              <input
                name={'name'}
                value={inputValue.name}
                onChange={handleChange}
                type="text"
                className={
                  !errName && !isLaptopWidth && !isLandscape
                    ? 'nameInputMob'
                    : !errName && isLaptopWidth && !isLandscape
                    ? 'nameInputLap'
                    : inputValue.name.length > 1 &&
                      !isLaptopWidth &&
                      !isLandscape
                    ? 'nameInputMob'
                    : isLaptopWidth &&
                      inputValue.name.length > 1 &&
                      !isLandscape
                    ? 'nameInputLap'
                    : !isLaptopWidth && !isLandscape
                    ? 'nameErrMob'
                    : isLandscape && !errName
                    ? 'nameInputLand'
                    : isLandscape && inputValue.name.length > 1
                    ? 'nameInputLand'
                    : isLandscape && errName
                    ? 'nameErrLand'
                    : 'nameErrLap'
                }
                placeholder={t('inputs.name')}
              />
              <input
                name={'email'}
                value={inputValue.email}
                type="email"
                onChange={handleChange}
                className={
                  !errName && !isLaptopWidth && !isLandscape
                    ? 'nameInputMob'
                    : !errName && isLaptopWidth && !isLandscape
                    ? 'nameInputLap'
                    : inputValue.email.length > 1 &&
                      !isLaptopWidth &&
                      !isLandscape
                    ? 'nameInputMob'
                    : isLaptopWidth &&
                      inputValue.email.length > 1 &&
                      !isLandscape
                    ? 'nameInputLap'
                    : !isLaptopWidth && !isLandscape
                    ? 'nameErrMob'
                    : isLandscape && !errName
                    ? 'nameInputLand'
                    : isLandscape && inputValue.email.length > 1
                    ? 'nameInputLand'
                    : isLandscape && errName
                    ? 'nameErrLand'
                    : 'nameErrLap'
                }
                placeholder={'Email'}
              />
              <textarea
                className={
                  !errValue && !isLaptopWidth && !isLandscape
                    ? 'textareaMob'
                    : !errValue && isLaptopWidth && !isLandscape
                    ? 'textareaLap'
                    : inputValue.aboutProject.length > 1 &&
                      !isLaptopWidth &&
                      !isLandscape
                    ? 'textareaMob'
                    : inputValue.aboutProject.length > 1 &&
                      !isLaptopWidth &&
                      !isLandscape
                    ? 'textareaLap'
                    : !isLaptopWidth && !isLandscape
                    ? 'areaErrMob'
                    : (isLandscape && !errValue) ||
                      (isLandscape && inputValue.aboutProject.length > 1)
                    ? 'textareaLand'
                    : isLandscape && errValue
                    ? 'areaErrLand'
                    : 'areaErrLap'
                }
                name="aboutProject"
                value={inputValue.aboutProject}
                onChange={handleChange}
                cols={30}
                rows={10}
                placeholder={t('inputs.textarea')}
              />
              <button
                type={'button'}
                className={
                  !isLaptopWidth && !isLandscape
                    ? 'modalBtnMob'
                    : isLandscape
                    ? 'modalBtnLand'
                    : 'modalBtnLap'
                }
                onClick={checkInputs}
              >
                Confirm
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div
          className={
            isLandscape
              ? 'sucessMassageLand'
              : isBigLaptop && !isLandscape
              ? 'sucessMassageLap'
              : sucessMassageMob
          }
        >
          <p>{t('popUp.text')}</p>
          <SucessSvg
            width={isLandscape ? 350 : 550}
            height={isLandscape ? 350 : 550}
          />
        </div>
      )}
    </div>
  )
}

export default InputMobScreen
