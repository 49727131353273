import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function ServiceLine1(props: SvgProps) {
  const { width, height, className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 800 11"
    >
      <path
        fill="#2B38B0"
        fillRule="evenodd"
        d="M794.5 0a5.5 5.5 0 015.5 5.5 5.5 5.5 0 01-5.5 5.5 5.5 5.5 0 01-5.41-4.5H0v-1h789a5.5 5.5 0 015.5-5.5z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ServiceLine1

ServiceLine1.defaultProps = {
  width: 800,
  height: 11,
}
