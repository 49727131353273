//modules
import React from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import * as Scroll from 'react-scroll'
//interfaces
import { ScreenProps } from '../../interfaces/ScreenProps'
//styles
import './style.css'

const Footer = ({ setIndex, isMobileScreen, isLandscape }: ScreenProps) => {
  let Link = Scroll.Link
  const { t } = useTranslation()

  return (
    <div
      className={
        isMobileScreen && !isLandscape ? 'mainFooterMob' : 'mainFooter'
      }
    >
      <div
        className={
          isMobileScreen && !isLandscape
            ? 'footerContentPositionMob'
            : 'footerContentPosition'
        }
      >
        <div
          className={
            isMobileScreen && !isLandscape
              ? 'footerLinkContainerMob'
              : 'footerLinkContainer'
          }
        >
          <div>
            <p
              className={
                isMobileScreen && !isLandscape ? 'titleLinkMob' : 'titleLink'
              }
            >
              {t('footer.AboutTitle')}
            </p>
            <Link
              className={'footerLink'}
              to={'home'}
              onClick={() => setIndex(0)}
              smooth={true}
            >
              {t('buttons.homeBtn')}
            </Link>
            <Link
              className={'footerLink'}
              to={'experience'}
              smooth={true}
              onClick={() => setIndex(1)}
            >
              {t('buttons.expBtn')}
            </Link>
            <Link
              className={'footerLink'}
              to={'approach'}
              smooth={true}
              onClick={() => setIndex(2)}
            >
              {t('buttons.approachBtn')}
            </Link>
          </div>
          <div>
            <p
              className={
                isMobileScreen && !isLandscape ? 'titleLinkMob' : 'titleLink'
              }
            >
              {t('footer.ServiceTitle')}
            </p>
            <Link
              className={'footerLink'}
              to={'service'}
              smooth={true}
              onClick={() => setIndex(3)}
            >
              {t('buttons.serviceBtn')}
            </Link>
            <Link
              className={'footerLink'}
              to={'ourTeam'}
              smooth={true}
              onClick={() => setIndex(4)}
            >
              {t('buttons.TeamBtn')}
            </Link>
            <Link
              className={'footerLink'}
              to={'technology'}
              smooth={true}
              onClick={() => setIndex(5)}
            >
              {t('buttons.technologyBtn')}
            </Link>
          </div>
          <div>
            <p
              className={
                isMobileScreen && !isLandscape ? 'titleLinkMob' : 'titleLink'
              }
            >
              {t('footer.ConnectionTitle')}
            </p>
            <Link
              className={'footerLink'}
              to={'getProject'}
              smooth={true}
              onClick={() => setIndex(6)}
            >
              {t('buttons.getProjectBtn')}
            </Link>
          </div>
        </div>
        <div
          className={
            isMobileScreen && !isLandscape
              ? 'footerContactMob'
              : 'footerContact'
          }
        >
          <div>
            <p
              className={
                isMobileScreen && !isLandscape
                  ? 'titleContactMob'
                  : 'titleContact'
              }
            >
              {t('footer.ContactTitle')}
            </p>
            <p>{t('footer.ContactText')}: +7 936 316-40-60</p>
            <p>email: ord.forDream@gmail.com</p>
            <p>telegram: @ForDream_FD</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
