// modules
import React from 'react'
import { motion } from 'framer-motion'
// utils
import { imgAnimation } from '../../../../utils/animation/data'
// assets
import { images } from '../../../../assets/image'
import { ScreenProps } from '../../../../interfaces/ScreenProps'

const HomeImg = ({
  isMobileScreen,
  isBigLaptop,
  isLandscape,
  isLaptopWidth,
}: ScreenProps) => {
  return (
    <motion.div
      className={
        isMobileScreen && !isBigLaptop && !isLandscape && !isLaptopWidth
          ? 'imgMob'
          : isMobileScreen && isBigLaptop && !isLandscape && !isLaptopWidth
          ? 'imgBLap'
          : isMobileScreen && isLandscape && !isLaptopWidth
          ? 'imgLand'
          : isMobileScreen && isLaptopWidth
          ? 'imgLap'
          : 'imgContainer'
      }
      initial={'hidden'}
      whileInView={'visible'}
    >
      <div>
        <motion.img
          custom={1}
          variants={imgAnimation}
          src={images.groundFD}
          alt="pics"
          className={
            isMobileScreen && !isBigLaptop && !isLandscape
              ? 'homeImgMob'
              : isMobileScreen && isBigLaptop && !isLandscape
              ? 'homeImgBLap'
              : isMobileScreen && isLandscape
              ? 'homeImgLand'
              : 'homeImg'
          }
        />
      </div>
    </motion.div>
  )
}

export default HomeImg
