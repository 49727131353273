//Modules
import React, { memo, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'
//components
import { ChildPage, MChildPage } from '../ChildPage'
import NavigationText from '../../components/navigationText'
import MobileSwitcher from './components/MobileSwitcher'
import ComputerSwitcher from './components/ComputerSwitcher'
//interface
import { ScreenProps } from '../../interfaces/ScreenProps'
// utils
import { lineAnimation } from '../../utils/animation/data'
// assets
import {
  ServiceLayout,
  ServiceLine1,
  ServiceLine2,
  ServiceMobile,
  ServiceUi,
  ServiceWeb,
} from '../../assets/svg'
//style
import { ServiceScreen } from './style'
import './style.css'

function Service(props: ScreenProps) {
  const {
    isMobileScreen,
    isBigLaptop,
    isBigScreen,
    isLaptopWidth,
    isSmallScreen,
    isLandscape,
    customSlider,
    setActive,
    active,
  } = props
  const { t } = useTranslation()
  const ServiceUiArr = t('service.UI', { returnObjects: true })
  const ServiceWebArr = t('service.web', { returnObjects: true })
  const ServiceMobileArr = t('service.mobile', { returnObjects: true })
  const ServiceLayoutArr = t('service.layout', { returnObjects: true })

  const currentSlide = (slideNum: number) => {
    setActive(slideNum)
    customSlider.current.slickGoTo(slideNum)
  }

  return (
    <ServiceScreen>
      {isMobileScreen ? (
        <NavigationText
          animation={{ amount: 0.5, once: true }}
          isLandscape={isLandscape}
          isMobileScreen={isMobileScreen}
          isBigLaptop={isBigLaptop}
          isLaptopWidth={isLaptopWidth}
          classLaptop={'navigationServiceLaptop'}
          classBigLaptop={'navigationServiceBLaptop'}
          classMobile={'navigationService'}
          textNavigation={t('buttons.serviceBtn')}
          classLandScape={'navigationServiceLand'}
        />
      ) : null}
      <div className={isMobileScreen ? 'serviceSliderMob' : 'serviceSlider'}>
        <motion.div
          initial={'hidden'}
          whileInView={'visible'}
          viewport={{ amount: 0.5 }}
        >
          <Slider
            swipe={false}
            arrows={false}
            ref={customSlider}
            className={
              isMobileScreen && !isLaptopWidth && !isLandscape
                ? 'sliderService'
                : isMobileScreen &&
                  isLaptopWidth &&
                  !isBigScreen &&
                  !isLandscape
                ? 'sliderServiceLaptop'
                : isMobileScreen && isBigScreen && !isLandscape
                ? 'sliderServiceBLaptop'
                : isMobileScreen && isLandscape
                ? 'sliderServiceLand'
                : ''
            }
            draggable={false}
          >
            <MChildPage
              isLandscape={isLandscape}
              isMobileScreen={isMobileScreen}
              isBigLaptop={isBigLaptop}
              isLaptopWidth={isLaptopWidth}
              variants={lineAnimation}
              custom={1}
              line1={
                isBigScreen ? (
                  <ServiceLine1 className={'serviceLine1'} width={1200} />
                ) : isSmallScreen ? (
                  <ServiceLine1 className={'serviceLine1'} width={850} />
                ) : (
                  <ServiceLine1 className={'serviceLine1'} />
                )
              }
              line2={
                isBigScreen ? (
                  <ServiceLine2 className={'serviceLine2'} />
                ) : isSmallScreen ? (
                  <ServiceLine2 className={'serviceLine2'} width={900} />
                ) : (
                  <ServiceLine2 className={'serviceLine2'} />
                )
              }
              text={ServiceUiArr}
              image={
                <ServiceUi
                  width={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : isBigLaptop && !isLandscape
                      ? 350
                      : isMobileScreen && isLandscape
                      ? 350
                      : 312
                  }
                  height={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : (isBigLaptop && !isLandscape) ||
                        (isMobileScreen && isLandscape)
                      ? 350
                      : 312
                  }
                />
              }
            />
            <ChildPage
              isLandscape={isLandscape}
              isMobileScreen={isMobileScreen}
              isBigLaptop={isBigLaptop}
              isLaptopWidth={isLaptopWidth}
              line1={
                isBigScreen ? (
                  <ServiceLine1 className={'serviceLine1'} width={1200} />
                ) : isSmallScreen ? (
                  <ServiceLine1 className={'serviceLine1'} width={850} />
                ) : (
                  <ServiceLine1 className={'serviceLine1'} />
                )
              }
              line2={
                isBigScreen ? (
                  <ServiceLine2 className={'serviceLine2'} />
                ) : isSmallScreen ? (
                  <ServiceLine2 className={'serviceLine2'} width={900} />
                ) : (
                  <ServiceLine2 className={'serviceLine2'} />
                )
              }
              text={ServiceWebArr}
              image={
                <ServiceWeb
                  width={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : isBigLaptop && !isLandscape
                      ? 350
                      : isMobileScreen && isLandscape
                      ? 350
                      : 312
                  }
                  height={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : (isBigLaptop && !isLandscape) ||
                        (isMobileScreen && isLandscape)
                      ? 350
                      : 312
                  }
                />
              }
            />
            <ChildPage
              isLandscape={isLandscape}
              isMobileScreen={isMobileScreen}
              isBigLaptop={isBigLaptop}
              isLaptopWidth={isLaptopWidth}
              line1={
                isBigScreen ? (
                  <ServiceLine1 className={'serviceLine1'} width={1200} />
                ) : isSmallScreen ? (
                  <ServiceLine1 className={'serviceLine1'} width={850} />
                ) : (
                  <ServiceLine1 className={'serviceLine1'} />
                )
              }
              line2={
                isBigScreen ? (
                  <ServiceLine2 className={'serviceLine2'} />
                ) : isSmallScreen ? (
                  <ServiceLine2 className={'serviceLine2'} width={900} />
                ) : (
                  <ServiceLine2 className={'serviceLine2'} />
                )
              }
              text={ServiceMobileArr}
              image={
                <ServiceMobile
                  width={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : isBigLaptop && !isLandscape
                      ? 350
                      : isMobileScreen && isLandscape
                      ? 350
                      : 312
                  }
                  height={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : (isBigLaptop && !isLandscape) ||
                        (isMobileScreen && isLandscape)
                      ? 350
                      : 312
                  }
                />
              }
            />
            <ChildPage
              isLandscape={isLandscape}
              isMobileScreen={isMobileScreen}
              isBigLaptop={isBigLaptop}
              isLaptopWidth={isLaptopWidth}
              line1={
                isBigScreen ? (
                  <ServiceLine1 className={'serviceLine1'} width={1200} />
                ) : isSmallScreen ? (
                  <ServiceLine1 className={'serviceLine1'} width={850} />
                ) : (
                  <ServiceLine1 className={'serviceLine1'} />
                )
              }
              line2={
                isBigScreen ? (
                  <ServiceLine2 className={'serviceLine2'} />
                ) : isSmallScreen ? (
                  <ServiceLine2 className={'serviceLine2'} width={900} />
                ) : (
                  <ServiceLine2 className={'serviceLine2'} />
                )
              }
              text={ServiceLayoutArr}
              image={
                <ServiceLayout
                  width={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : isBigLaptop && !isLandscape
                      ? 350
                      : isMobileScreen && isLandscape
                      ? 350
                      : 312
                  }
                  height={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : (isBigLaptop && !isLandscape) ||
                        (isMobileScreen && isLandscape)
                      ? 350
                      : 312
                  }
                />
              }
            />
          </Slider>
          <div
            className={
              isMobileScreen ? 'serviceBtnContainerMob' : 'serviceBtnContainer'
            }
          >
            {!isMobileScreen ? (
              <ComputerSwitcher
                active={active}
                onClick1={() => currentSlide(0)}
                onClick2={() => currentSlide(1)}
                onClick3={() => currentSlide(2)}
                onClick4={() => currentSlide(3)}
              />
            ) : (
              <MobileSwitcher
                serviceLandSwitcher={'serviceLandSwitcher'}
                isLandscape={isLandscape}
                isBigLaptop={isBigLaptop}
                isLaptopWidth={isLaptopWidth}
                onClick1={() => currentSlide(0)}
                onClick2={() => currentSlide(1)}
                onClick3={() => currentSlide(2)}
                onClick4={() => currentSlide(3)}
                active={active}
              />
            )}
          </div>
        </motion.div>
      </div>
    </ServiceScreen>
  )
}

export default memo(Service)
