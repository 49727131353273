//modules
import React from 'react'
import { motion } from 'framer-motion'
// utils
import { svgAnimation } from '../../../../utils/animation/data'

// assets
import MobSlideSvg from '../../../../assets/svg/MobSlideSvg'
// interfaces
import { SvgProps } from '../../../../interfaces/SvgProps'

const MobileSwitcher = (props: SvgProps) => {
  const {
    onClick1,
    onClick2,
    onClick3,
    onClick4,
    active,
    isBigLaptop,
    isLaptopWidth,
    isLandscape,
    serviceLandSwitcher,
  } = props

  return (
    <motion.div
      className={
        !isLaptopWidth && !isLandscape
          ? 'serviceMobSwitcher'
          : isBigLaptop && !isLandscape && !isLaptopWidth
          ? 'serviceBLapSwitcher'
          : isLandscape
          ? serviceLandSwitcher
          : 'serviceLapSwitcher'
      }
      initial={'hidden'}
      whileInView={'visible'}
      viewport={{ amount: 0.5 }}
    >
      <motion.div custom={2} variants={svgAnimation}>
        <MobSlideSvg
          width={
            isBigLaptop && !isLandscape
              ? 900
              : !isBigLaptop && isLaptopWidth && !isLandscape
              ? 950
              : isLandscape
              ? 800
              : 1000
          }
          height={
            isBigLaptop && !isLandscape
              ? 120
              : !isBigLaptop && isLaptopWidth && !isLandscape
              ? 135
              : isLandscape
              ? 110
              : 150
          }
          onClick1={onClick1}
          onClick2={onClick2}
          onClick3={onClick3}
          onClick4={onClick4}
          radius1={active === 0 ? 22 : 11}
          radius2={active === 1 ? 22 : 11}
          radius3={active === 2 ? 22 : 11}
          radius4={active === 3 ? 22 : 11}
        />
      </motion.div>
    </motion.div>
  )
}

export default MobileSwitcher
