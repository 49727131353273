import React from 'react'
import { SvgProps } from '../../../interfaces/SvgProps'

function ApproachNum4(props: SvgProps) {
  const { width, height, className, onClick } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      fill="none"
      viewBox="0 0 322 445"
    >
      <path
        fill="url(#paint0_linear_599_27716)"
        d="M321.219 295.464v46.387H.174v-33.265L199.15.664h46.081l-49.438 89.111-131.53 205.689h256.957zM259.269.664V445h-56.458V.664h56.458z"
        opacity="0.49"
      />
      <defs>
        <linearGradient
          id="paint0_linear_599_27716"
          x1="-16"
          x2="350.667"
          y1="231.006"
          y2="231.006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
export default ApproachNum4

ApproachNum4.defaultProps = {
  width: 322,
  height: 445,
}
