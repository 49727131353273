import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

const ArrowBack = ({ width, height, className }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 20 40"
    >
      <path
        fill="url(#paint0_linear_260_1618)"
        d="M16.681 40a2.856 2.856 0 01-2.228-1.057L.65 21.802a2.856 2.856 0 010-3.629l14.288-17.14a2.861 2.861 0 014.401 3.656L6.565 20.002 18.91 35.315A2.857 2.857 0 0116.681 40z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_260_1618"
          x1="0"
          x2="20.833"
          y1="20"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"></stop>
          <stop offset="1" stopColor="#2033E0"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ArrowBack

ArrowBack.defaultProps = {
  width: 20,
  height: 40,
}
