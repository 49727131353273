// modules
import React, { memo } from 'react'
import { motion } from 'framer-motion'
// utils
import {
  AboutSlider,
  NaumAnimation,
  textAnimation,
} from '../../../../utils/animation/data'
// interfaces
import { MainSlideProps } from '../../../../interfaces/MainScreenProps'

const ExpContent = ({
  index,
  title,
  text,
  isBigLaptop,
  isMobileScreen,
  isLandscape,
  isLaptopWidth,
  setScreen,
  array,
}: MainSlideProps) => {
  return (
    <motion.div key={index} variants={AboutSlider}>
      <div
        className={
          isMobileScreen && !isLandscape
            ? 'expTextContainerMob'
            : 'expTextContainer'
        }
      >
        <div className={'expContent'}>
          <p
            className={
              isMobileScreen && !isLaptopWidth && !isBigLaptop && !isLandscape
                ? 'expTitleMob'
                : isMobileScreen && isBigLaptop && !isLandscape
                ? 'expTitleBLap'
                : isMobileScreen && isLandscape
                ? 'expTitleLand'
                : isMobileScreen && isLaptopWidth
                ? 'expTitleLap'
                : 'expTitle'
            }
          >
            {title}
          </p>
          <p
            className={
              isMobileScreen && !isBigLaptop && !isLaptopWidth && !isLandscape
                ? 'expTextMob'
                : isMobileScreen && isBigLaptop && !isLandscape
                ? 'expTextBLap'
                : isMobileScreen && isLandscape
                ? 'expTextLand'
                : isMobileScreen && isLaptopWidth
                ? 'expTextLap'
                : 'expText'
            }
          >
            {text}
          </p>
        </div>
        <div
          className={
            isMobileScreen ? 'expContentContainerMob' : 'expContentContainer'
          }
        >
          <div
            className={
              isMobileScreen && !isLaptopWidth && !isLandscape
                ? 'expImgContainerMob'
                : isMobileScreen && isLaptopWidth && !isLandscape
                ? 'expImgContainerLap'
                : isMobileScreen && isLandscape
                ? 'expImgContainerLand'
                : 'expImgContainer'
            }
          >
            {array.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item.image}
                  alt="img"
                  className={
                    isMobileScreen && !isLandscape ? 'expImgMob' : 'expImg'
                  }
                  onClick={() => setScreen(item.id)}
                />
              )
            })}
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default memo(ExpContent)
