//modules
import React from 'react'
import { useTranslation } from 'react-i18next'
// utils
import '../../utils/i18next'
//style
import './style.css'

function Switch({ isMobileScreen, language, isLandscape }: any) {
  const { i18n } = useTranslation()
  const changeLanguage = (lang: string) => i18n.changeLanguage(lang)

  return (
    <label
      className={
        isMobileScreen && !isLandscape
          ? 'switchMob'
          : isMobileScreen && isLandscape
          ? 'switchLand'
          : 'switch'
      }
    >
      <input
        type="checkbox"
        id={'checkbox'}
        checked={language == 'ru'}
        onChange={() => {
          language == 'ru' ? changeLanguage('en') : changeLanguage('ru')
        }}
      />
      <span
        className={
          isMobileScreen && !isLandscape
            ? 'sliderMob'
            : isMobileScreen && isLandscape
            ? 'sliderLand'
            : 'slider'
        }
      />
    </label>
  )
}

export default Switch
