import React from 'react'
import { SvgProps } from '../../../interfaces/SvgProps'

function ApproachNum1(props: SvgProps) {
  const { width, height, className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 171 447"
    >
      <path
        fill="url(#paint0_linear_599_27362)"
        d="M170.473.223V447h-56.457V70.718L.186 112.222V61.258L161.622.223h8.85z"
        opacity="0.49"
      />
      <defs>
        <linearGradient
          id="paint0_linear_599_27362"
          x1="-52"
          x2="314.667"
          y1="233.006"
          y2="233.006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
export default ApproachNum1

ApproachNum1.defaultProps = {
  width: 171,
  height: 447,
}
