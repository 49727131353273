import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function TechnoLine2(props: SvgProps) {
  const { width, height, className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 800 11"
    >
      <path
        fill="#2B38B0"
        fillRule="evenodd"
        d="M794.5 11a5.5 5.5 0 10-5.409-6.5H0v1h789a5.5 5.5 0 005.5 5.5z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default TechnoLine2

TechnoLine2.defaultProps = {
  width: 800,
  height: 11,
}
