//module
import React, { ReactElement } from 'react'
import { motion } from 'framer-motion'
// interface
import { ChildPageProps } from '../../interfaces/ChildPageProps'
// style
import './style.css'
import { ChildScreen } from './style'

export const ChildPage = (props: ChildPageProps) => {
  const {
    text,
    image,
    isBigLaptop,
    isLaptopWidth,
    isMobileScreen,
    isLandscape,
  } = props

  return (
    <ChildScreen>
      <div
        className={
          isMobileScreen && !isLaptopWidth && !isLandscape
            ? 'serviceContainerMob'
            : isMobileScreen && isLaptopWidth && !isBigLaptop && !isLandscape
            ? 'serviceContainerLap'
            : isMobileScreen && isBigLaptop && !isLandscape
            ? 'serviceContainerBLap'
            : isMobileScreen && isLandscape
            ? 'serviceContainerLand'
            : 'serviceContainer'
        }
      >
        <div>
          <div
            className={
              isMobileScreen && !isBigLaptop && !isLaptopWidth && !isLandscape
                ? 'iconContainerMob'
                : isMobileScreen && isBigLaptop && !isLandscape
                ? 'iconContainerBLap'
                : isMobileScreen &&
                  isLaptopWidth &&
                  !isBigLaptop &&
                  !isLandscape
                ? 'iconContainerLap'
                : isMobileScreen && isLandscape
                ? 'iconContainerLap'
                : 'iconContainer'
            }
          >
            {image}
          </div>
          <div
            className={
              isMobileScreen && !isLaptopWidth && !isLandscape
                ? 'serviceTextPositionMob'
                : isMobileScreen &&
                  isLaptopWidth &&
                  !isBigLaptop &&
                  !isLandscape
                ? 'serviceTextPositionLap'
                : isMobileScreen && isBigLaptop && !isLandscape
                ? 'serviceTextPositionBLap'
                : isMobileScreen && isLandscape
                ? 'serviceTextPositionLand'
                : 'serviceTextPosition'
            }
          >
            {text.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={
                    isMobileScreen && !isLaptopWidth
                      ? 'TodoContainerMob'
                      : isMobileScreen && isLaptopWidth
                      ? 'TodoContainerLap'
                      : 'TodoContainer'
                  }
                >
                  <ul>
                    <li
                      className={
                        isMobileScreen && !isLaptopWidth && !isLandscape
                          ? 'UiTextMob'
                          : isMobileScreen &&
                            isLaptopWidth &&
                            !isBigLaptop &&
                            !isLandscape
                          ? 'UiTextLap'
                          : isMobileScreen && isBigLaptop && !isLandscape
                          ? 'UiTextBLap'
                          : isLandscape && isMobileScreen
                          ? 'UiTextLand'
                          : 'UiText'
                      }
                    >
                      {item.text}
                    </li>
                  </ul>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </ChildScreen>
  )
}

export const MChildPage = motion(ChildPage)
