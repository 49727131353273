//modules
import React from 'react'
// @ts-ignore
import * as Scroll from 'react-scroll'
// @ts-ignore
import { Link } from 'react-scroll'
//components
import Switch from '../switch/switch'
//style
import './style.css'
//interface
import { BurgerProps } from '../../interfaces/BurgerProps'

const Menu = ({
  active,
  setActive,
  items,
  isLaptopWidth,
  isMobileScreen,
  language,
  isLandscape,
}: BurgerProps) => {
  return (
    <div
      className={active ? 'menu active' : 'menu'}
      onClick={() => setActive(false)}
    >
      <div className={active ? 'blur active' : 'blur'} />
      <div className={'menuContent'} onClick={(e) => e.stopPropagation()}>
        <ul className={!isLandscape ? 'todoContainerMob' : 'todoContainerLand'}>
          {items.map((item: any, index: number) => {
            return (
              <div
                className={
                  !isLaptopWidth && !isLandscape
                    ? 'linkBurgerContainer'
                    : isLandscape
                    ? 'linkBurgerContainerLand'
                    : 'linkBurgerContainerLap'
                }
                key={index}
              >
                <li>
                  <Link
                    to={item.path}
                    spy={true}
                    smooth={true}
                    activeClass={'navActiveMob'}
                    className={'navigateLink'}
                    onClick={() => {
                      setTimeout(() => setActive(false), 500)
                    }}
                  >
                    {item.name}
                  </Link>
                </li>
              </div>
            )
          })}
          <div>
            <Switch
              isMobileScreen={isMobileScreen}
              language={language}
              isLandscape={isLandscape}
            />
          </div>
        </ul>
      </div>
    </div>
  )
}

export default Menu
