// modules
import React from 'react'
import { motion } from 'framer-motion'
// utils
import { imgAnimation } from '../../../../utils/animation/data'
// interface
import { ScreenProps } from '../../../../interfaces/ScreenProps'
// assets
import { images } from '../../../../assets/image'

const AboutImg = ({
  isMobileScreen,
  isBigLaptop,
  isLandscape,
}: ScreenProps) => {
  return (
    <div
      className={
        isMobileScreen && !isLandscape
          ? 'aboutImgMob'
          : isMobileScreen && isLandscape
          ? 'aboutImgLand'
          : 'aboutImgContainer'
      }
    >
      <motion.div custom={1} variants={imgAnimation}>
        <img
          src={images.groundFD}
          alt="pics"
          className={
            isMobileScreen && !isBigLaptop && !isLandscape
              ? 'aboutImgMobile'
              : isMobileScreen && isBigLaptop && !isLandscape
              ? 'aboutImgBLaptop'
              : isMobileScreen && isLandscape
              ? 'aboutImgBLaptop'
              : 'aboutImg'
          }
        />
      </motion.div>
    </div>
  )
}

export default AboutImg
