//modules
import React, { memo, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
// @ts-ignore
import * as Scroll from 'react-scroll'
import { useTranslation } from 'react-i18next'
//screens
import {
  ArchiveSystem,
  AutoPart,
  CigarShop,
  ElectricBike,
  FinSystem,
  Firewall,
  Fitness,
  Health,
  ILX,
  MainPage,
  Management,
  OnlineStore,
} from '../index'
//styles
import './style.css'

const MainComponent = () => {
  const { t } = useTranslation()
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1144px)' })
  const isLaptopWidth = useMediaQuery({ minDeviceWidth: 500 })
  const [screen, setScreen] = useState(0)
  const [activeExp, setActiveExp] = useState<boolean>(false)
  const [index, setIndex] = useState(0)
  const language = localStorage.getItem('i18nextLng')

  const customSwapExp = useRef<any>(null)

  let scroller = Scroll.scroller
  useEffect(() => {
    const data = localStorage.getItem('id')
    if (data) {
      setIndex(JSON.parse(data))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('id', JSON.stringify(index))
  }, [index])

  function scrollToTop(slideNum: number) {
    setScreen(0)
    setActiveExp(true)
    if (isMobileScreen) {
      setTimeout(() => {
        scroller.scrollTo('experience', {
          smooth: true,
          duration: 50,
        })
        customSwapExp.current.slickGoTo(slideNum)
      }, 100)
    }
    setTimeout(() => {
      scroller.scrollTo('experience')
      customSwapExp.current.slickGoTo(slideNum)
    }, 1)
  }

  function toHome() {
    setScreen(0)
    setIndex(0)
    setTimeout(() => scroller.scrollTo('home'), 100)
  }

  return (
    <>
      {screen === 0 ? (
        <MainPage
          screen={screen}
          customSwapExp={customSwapExp}
          activeExp={activeExp}
          setActiveExp={setActiveExp}
          language={language}
          index={index}
          setIndex={setIndex}
          setScreen={setScreen}
          headerClick={() => setIndex(0)}
        />
      ) : screen === 1 ? (
        <div className={'mainCaseDiv'}>
          <Fitness
            isLaptopWidth={isLaptopWidth}
            onClick={() => scrollToTop(0)}
            language={language}
            isMobileScreen={isMobileScreen}
            headerClick={() => toHome()}
          />
        </div>
      ) : screen === 2 ? (
        <Management
          isLaptopWidth={isLaptopWidth}
          onClick={() => scrollToTop(0)}
          language={language}
          isMobileScreen={isMobileScreen}
          headerClick={() => toHome()}
        />
      ) : screen === 3 ? (
        <OnlineStore
          isLaptopWidth={isLaptopWidth}
          onClick={() => scrollToTop(0)}
          language={language}
          isMobileScreen={isMobileScreen}
          headerClick={() => toHome()}
        />
      ) : screen === 4 ? (
        <ElectricBike
          isLaptopWidth={isLaptopWidth}
          onClick={() => scrollToTop(1)}
          language={language}
          isMobileScreen={isMobileScreen}
          headerClick={() => toHome()}
        />
      ) : screen === 5 ? (
        <CigarShop
          isLaptopWidth={isLaptopWidth}
          onClick={() => scrollToTop(1)}
          language={language}
          isMobileScreen={isMobileScreen}
          headerClick={() => toHome()}
        />
      ) : screen === 6 ? (
        <AutoPart
          isLaptopWidth={isLaptopWidth}
          onClick={() => scrollToTop(1)}
          language={language}
          isMobileScreen={isMobileScreen}
          headerClick={() => toHome()}
        />
      ) : screen === 7 ? (
        <Health
          isLaptopWidth={isLaptopWidth}
          onClick={() => scrollToTop(2)}
          language={language}
          isMobileScreen={isMobileScreen}
          headerClick={() => toHome()}
        />
      ) : screen === 8 ? (
        <FinSystem
          isLaptopWidth={isLaptopWidth}
          onClick={() => scrollToTop(2)}
          language={language}
          isMobileScreen={isMobileScreen}
          headerClick={() => toHome()}
        />
      ) : screen === 9 ? (
        <ILX
          isLaptopWidth={isLaptopWidth}
          onClick={() => scrollToTop(2)}
          language={language}
          isMobileScreen={isMobileScreen}
          headerClick={() => toHome()}
        />
      ) : screen === 10 ? (
        <ArchiveSystem
          isLaptopWidth={isLaptopWidth}
          onClick={() => scrollToTop(2)}
          language={language}
          isMobileScreen={isMobileScreen}
          headerClick={() => toHome()}
        />
      ) : screen === 11 ? (
        <Firewall
          isLaptopWidth={isLaptopWidth}
          onClick={() => scrollToTop(2)}
          language={language}
          isMobileScreen={isMobileScreen}
          headerClick={() => toHome()}
        />
      ) : (
        <MainPage index={index} setIndex={setIndex} setScreen={setScreen} />
      )}
    </>
  )
}

export default memo(MainComponent)
