// modules
import React, { memo } from 'react'
import Slider from 'react-slick'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
// components
import ExpContent from './components/ExpContent'
// utils
import '../../utils/i18next'
//style
import './style.css'
//interface
import { ScreenProps } from '../../interfaces/ScreenProps'
import { SettingsExp } from '../../interfaces/SettingsExp'

function Experience(props: ScreenProps) {
  const {
    isMobileScreen,
    isLaptopWidth,
    isBigLaptop,
    isLandscape,
    setScreen,
    customSwapExp,
  } = props
  const { t } = useTranslation()
  const expArr: any[] = t('experience', { returnObjects: true })

  const settings: SettingsExp = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <motion.div
      className={
        isMobileScreen && !isLaptopWidth && !isLandscape
          ? 'expDivMob'
          : isMobileScreen && isLaptopWidth && !isLandscape
          ? 'expDivLap'
          : isMobileScreen && isLandscape
          ? 'expDivLand'
          : 'expDiv'
      }
      initial={'hidden'}
      whileInView={'visible'}
      viewport={{ amount: 0.5, once: true }}
    >
      <Slider
        ref={customSwapExp}
        {...settings}
        className={
          isMobileScreen && !isLaptopWidth && !isLandscape
            ? 'expSliderMob'
            : isMobileScreen && isLaptopWidth && !isLandscape
            ? 'expSliderLap'
            : 'expSlider'
        }
        dotsClass={
          isMobileScreen && !isBigLaptop && !isLandscape
            ? 'slick-dots epxDotMob'
            : isMobileScreen && isBigLaptop && !isLandscape
            ? 'slick-dots epxDotBLap'
            : isMobileScreen && isLandscape
            ? 'slick-dots epxDotLand'
            : 'slick-dots epxDot'
        }
      >
        {expArr.map((item, index) => {
          return (
            <ExpContent
              setScreen={setScreen}
              array={item.img}
              isLandscape={isLandscape}
              key={index}
              text={item.text}
              title={item.title}
              index={index}
              isBigLaptop={isBigLaptop}
              isMobileScreen={isMobileScreen}
              isLaptopWidth={isLaptopWidth}
            />
          )
        })}
      </Slider>
    </motion.div>
  )
}

export default memo(Experience)
