import React from 'react'
import { SvgProps } from '../../../interfaces/SvgProps'

function ProjectCircle3(props: SvgProps) {
  let { width, height, className, onClick, radius1 } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 50 50"
      className={className}
      onClick={onClick}
    >
      <circle
        cx="25"
        cy="25"
        r="25"
        fill="url(#paint0_linear_599_27367)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_599_27367"
          x1="0"
          x2="52.083"
          y1="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ProjectCircle3

ProjectCircle3.defaultProps = {
  width: 50,
  height: 50,
}
