import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

const MobileCaseSvg = ({ width, height, className }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 319 349"
    >
      <path
        fill="#1B27A4"
        fillRule="evenodd"
        d="M261.032 8.721H84.426a4.36 4.36 0 00-4.36 4.361v322.689a4.36 4.36 0 004.36 4.36h176.606a4.36 4.36 0 004.361-4.36V13.082a4.36 4.36 0 00-4.361-4.36zM84.426 0C77.2 0 71.344 5.857 71.344 13.082v322.689c0 7.224 5.857 13.081 13.082 13.081h176.606c7.225 0 13.082-5.857 13.082-13.081V13.082C274.114 5.857 268.257 0 261.032 0H84.426z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#1B27A4"
        d="M123.672 6.541h97.024L211 25.934a4.361 4.361 0 01-3.9 2.41h-68.858a4.362 4.362 0 01-3.821-2.259L123.672 6.541z"
      ></path>
      <path
        fill="url(#paint0_linear_226_5101)"
        d="M152.018 14.172a3.271 3.271 0 11-6.542-.001 3.271 3.271 0 016.542 0z"
      ></path>
      <path
        fill="url(#paint1_linear_226_5101)"
        d="M167.281 14.172a5.451 5.451 0 11-10.902 0 5.451 5.451 0 0110.902 0z"
      ></path>
      <path
        fill="url(#paint2_linear_226_5101)"
        d="M182.542 14.172a5.45 5.45 0 11-10.901 0 5.45 5.45 0 0110.901 0z"
      ></path>
      <path
        fill="url(#paint3_linear_226_5101)"
        d="M197.804 14.172a5.45 5.45 0 11-10.901 0 5.45 5.45 0 0110.901 0z"
      ></path>
      <path
        fill="url(#paint4_linear_226_5101)"
        d="M113 84.5c0 31.204-25.296 56.5-56.5 56.5S0 115.704 0 84.5 25.296 28 56.5 28 113 53.296 113 84.5z"
      ></path>
      <path
        fill="#1B27A4"
        d="M56.984 185.992c0 6.623-5.37 11.992-11.992 11.992-6.623 0-11.992-5.369-11.992-11.992C33 179.369 38.369 174 44.992 174c6.623 0 11.992 5.369 11.992 11.992z"
      ></path>
      <path
        fill="#550FAD"
        fillRule="evenodd"
        d="M112.533 239.885c9.031 0 16.352-7.321 16.352-16.352s-7.321-16.353-16.352-16.353-16.353 7.322-16.353 16.353c0 9.031 7.322 16.352 16.353 16.352zm0 2.181c10.235 0 18.533-8.298 18.533-18.533 0-10.236-8.298-18.533-18.533-18.533C102.297 205 94 213.297 94 223.533c0 10.235 8.297 18.533 18.533 18.533z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#550FAD"
        d="M161.984 300.992c0 6.623-5.369 11.992-11.992 11.992-6.623 0-11.992-5.369-11.992-11.992 0-6.623 5.369-11.992 11.992-11.992 6.623 0 11.992 5.369 11.992 11.992z"
      ></path>
      <path
        fill="#550FAD"
        fillOpacity="0.51"
        d="M202.164 95.934a2.18 2.18 0 012.18-2.18H306.82a2.18 2.18 0 012.18 2.18V198.41a2.18 2.18 0 01-2.18 2.18H204.344a2.18 2.18 0 01-2.18-2.18V95.934z"
      ></path>
      <path
        fill="#550FAD"
        fillOpacity="0.51"
        d="M230 64.816c0-1.003.813-1.816 1.816-1.816h85.368c1.003 0 1.816.813 1.816 1.816v85.368a1.816 1.816 0 01-1.816 1.816h-85.368a1.816 1.816 0 01-1.816-1.816V64.816z"
      ></path>
      <path
        fill="#490FAC"
        fillRule="evenodd"
        d="M306.82 95.934H204.344V198.41H306.82V95.934zm-102.476-2.18a2.18 2.18 0 00-2.18 2.18V198.41a2.18 2.18 0 002.18 2.18H306.82a2.18 2.18 0 002.18-2.18V95.934a2.18 2.18 0 00-2.18-2.18H204.344z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#550FAD"
        d="M202.164 222.393a2.18 2.18 0 012.18-2.18H306.82a2.18 2.18 0 012.18 2.18v6.541a2.18 2.18 0 01-2.18 2.181H204.344a2.18 2.18 0 01-2.18-2.181v-6.541zM183 239.836a2.18 2.18 0 012.18-2.181h67.59a2.18 2.18 0 012.181 2.181v6.541a2.18 2.18 0 01-2.181 2.18h-67.59a2.18 2.18 0 01-2.18-2.18v-6.541zM219 257.279a2.18 2.18 0 012.18-2.18h93.754a2.18 2.18 0 012.181 2.18v6.541a2.18 2.18 0 01-2.181 2.18H221.18a2.18 2.18 0 01-2.18-2.18v-6.541zM192 274.721a2.18 2.18 0 012.18-2.18h71.951a2.18 2.18 0 012.18 2.18v6.541a2.18 2.18 0 01-2.18 2.181H194.18a2.18 2.18 0 01-2.18-2.181v-6.541z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_226_5101"
          x1="145.477"
          x2="152.29"
          y1="14.172"
          y2="14.172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"></stop>
          <stop offset="1" stopColor="#2033E0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_226_5101"
          x1="156.379"
          x2="167.735"
          y1="14.173"
          y2="14.173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"></stop>
          <stop offset="1" stopColor="#2033E0"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_226_5101"
          x1="171.641"
          x2="182.996"
          y1="14.173"
          y2="14.173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"></stop>
          <stop offset="1" stopColor="#2033E0"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_226_5101"
          x1="186.902"
          x2="198.258"
          y1="14.173"
          y2="14.173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"></stop>
          <stop offset="1" stopColor="#2033E0"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_226_5101"
          x1="0"
          x2="117.708"
          y1="84.501"
          y2="84.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"></stop>
          <stop offset="1" stopColor="#2033E0"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default MobileCaseSvg

MobileCaseSvg.defaultProps = {
  width: 319,
  height: 349,
}
