import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function ApproachLine(props: SvgProps) {
  let {
    className,
    onClick4,
    radius4,
    onClick1,
    radius1,
    onClick2,
    radius2,
    onClick3,
    radius3,
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="308"
      height="894"
      fill="none"
      className={className}
      viewBox="0 0 308 894"
    >
      <path stroke="#fff" d="M50 0v216.5h208.5V775H50v118.5" />
      <circle
        className={radius3 === 50 ? 'circle' : 'circleAnim'}
        onClick={onClick3}
        cx="258"
        cy="472"
        r={radius3}
        fill="url(#paint0_linear_599_27364)"
        stroke={radius3 === 50 ? 'white' : 'transparent'}
      />
      <circle
        className={radius2 === 50 ? 'circle' : 'circleAnim'}
        onClick={onClick2}
        cx="258"
        cy="217"
        r={radius2}
        fill="url(#paint1_linear_599_27364)"
        stroke={radius2 === 50 ? 'white' : 'transparent'}
      />
      <circle
        className={radius4 === 50 ? 'circle' : 'circleAnim'}
        onClick={onClick4}
        cx="258"
        cy="771.5"
        r={radius4}
        fill="url(#paint2_linear_599_27364)"
        stroke={radius4 === 50 ? 'white' : 'transparent'}
      />
      <circle
        className={radius1 === 50 ? 'circle' : 'circleAnim'}
        onClick={onClick1}
        cx="50"
        cy="217"
        r={radius1}
        fill="url(#paint3_linear_599_27364)"
        stroke={radius1 === 50 ? 'white' : 'transparent'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_599_27364"
          x1="233"
          x2="285.083"
          y1="472"
          y2="472"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_599_27364"
          x1="233"
          x2="285.083"
          y1="217"
          y2="217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_599_27364"
          x1="233"
          x2="285.083"
          y1="771.5"
          y2="771.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_599_27364"
          x1="0"
          x2="104.167"
          y1="217.001"
          y2="217.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ApproachLine

ApproachLine.defaultProps = {
  width: 210,
  height: 894,
  radius: 25,
}
