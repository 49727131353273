// modules
import React from 'react'
// components
import { Button } from '../../../../components/Button'
// interfaces
import { ProjectSliderProps } from '../../../../interfaces/ProjectSliderProps'
// constant

const ProjectSlider = (props: ProjectSliderProps) => {
  const { btnText, aboutProjectTitle, aboutProjectText, setModalActive } = props

  return (
    <div>
      <div className={'projectTextDiv'}>
        <div className={'projectPosition'}>
          <div>
            <span className={'projectTitle'}>{aboutProjectTitle}</span>
            <span className={'projectText'}>{aboutProjectText}</span>
            <div>
              <Button
                text={btnText}
                onClick={() => {
                  setModalActive(true)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/*<div className={'projectPositionText'}>*/}
      {/*  <div>*/}
      {/*    <span*/}
      {/*      className={*/}
      {/*        language === 'ru' ? 'localizationProject' : 'textProject'*/}
      {/*      }*/}
      {/*    >*/}
      {/*      {aboutProjectText}*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

export default ProjectSlider
