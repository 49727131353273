// modules
import React from 'react'
import { useMediaQuery } from 'react-responsive'
// interfaces
import { ApproachProps } from '../../../../interfaces/ApproachProps'
// constants

const ApproachCart = ({
  Image,
  text,
  isLaptopWidth,
  isBigLaptop,
  isLandscape,
  isMobileScreen,
}: ApproachProps) => {
  return (
    <div className={isMobileScreen ? 'approachPosition' : ''}>
      <div
        className={
          isMobileScreen && !isLandscape && !isLaptopWidth
            ? 'numContainerMob'
            : isMobileScreen && isLandscape && !isLaptopWidth
            ? 'numContainerMob'
            : isMobileScreen && isLaptopWidth
            ? 'numContainerLap'
            : 'numContainer'
        }
      >
        {Image}
      </div>
      <div
        className={
          isMobileScreen && !isLandscape
            ? 'appTextPositionMob'
            : isMobileScreen && isLandscape
            ? 'appTextPositionMob'
            : 'appTextPosition'
        }
      >
        <div
          className={
            isMobileScreen && !isLaptopWidth
              ? 'appTextContainerMob'
              : isMobileScreen && isLaptopWidth
              ? 'appTextContainerLap'
              : 'appTextContainer'
          }
        >
          <span
            className={
              isMobileScreen && !isBigLaptop && !isLandscape
                ? 'textApproachMob'
                : isMobileScreen && isBigLaptop && !isLandscape
                ? 'textApproachBLap'
                : isMobileScreen && isLandscape
                ? 'textApproachLand'
                : 'textApproach'
            }
          >
            {text}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ApproachCart
