//modules
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//components
import { Header } from '../../index'
import { ApproachNum1, ApproachNum2, ApproachNum3 } from '../../../assets/svg'
import MobileCaseSvg from '../../../assets/svg/MobileCaseSvg'
import ArrowBack from '../../../assets/svg/ArrowBack'
//image
import Fitness from '../../../assets/image/imgFitness.png'
//style
import './style.css'
//interface
import { CaseScreenProps } from '../../../interfaces/CaseScreenProps'
import ChildCase from '../ChildCase'

const FitnessCase = ({
  onClick,
  language,
  isMobileScreen,
  headerClick,
  isLaptopWidth,
}: CaseScreenProps) => {
  const { t } = useTranslation()

  const functionArr: any[] = t('fitness.functionArray', {
    returnObjects: true,
  })
  const problemArr: any[] = t('fitness.problemArray', {
    returnObjects: true,
  })
  const processArr: any[] = t('fitness.processArr', {
    returnObjects: true,
  })
  const outputArr: any[] = t('fitness.outputArr', {
    returnObjects: true,
  })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={'mainFitness'}>
      {isMobileScreen ? (
        <ChildCase
          headerClick={headerClick}
          title={t('fitness.title')}
          text={t('fitness.text')}
          isMobileScreen={isMobileScreen}
          functionArr={functionArr}
          functionTitle={t('management.functionTitle')}
          taskArr={problemArr}
          taskTitle={'Problems'}
          OutProjectArr={outputArr}
          processTitle={t('fitness.OutputTitle')}
          onClick={onClick}
          language={language}
          image={isMobileScreen ? null : Fitness}
          processArr={processArr}
          outputTitle={t('fitness.processTitle')}
          isLaptopWidth={isLaptopWidth}
        />
      ) : (
        <div>
          <div className={'headerCase'}>
            <div className={'headerContainer'}>
              <Header language={language} headerClick={headerClick} />
            </div>
          </div>
          <div className={'caseTitleChildContainer'}>
            <div className={'childContainer'}>
              <div className={'arrowBtnContainer'}>
                <div className={'arrowBack'} onClick={onClick}>
                  <ArrowBack className={'aboutArrow4'} />
                  <ArrowBack className={'aboutArrow3'} />
                  <ArrowBack className={'aboutArrow2'} />
                  <ArrowBack className={'aboutArrow1'} />
                </div>
              </div>
              <p className={'caseChildTitle'}>{t('fitness.title')}</p>
            </div>
            <div className={'childTextContainer'}>
              <p className={'caseChildText'}>{t('fitness.text')}</p>
            </div>
          </div>
          <div className={'functionContainer'}>
            <div className={'caseSvgContainer'}>
              <ApproachNum1 width={189} height={360} className={'caseNum'} />
            </div>
            <div className={'todoContainer'}>
              <ul className={'todoFunction'}>
                <p className={'funcTitle'}>{t('fitness.functionTitle')}:</p>
                {functionArr.map((item, index) => {
                  return <li key={index}>{item.text}</li>
                })}
              </ul>
            </div>
          </div>
          <div className={'mainProblemContainer'}>
            <div className={'problemContainer'}>
              <div className={'mobSvgContainer'}>
                <MobileCaseSvg />
              </div>
              <div>
                <div className={'problemSvgContainer'}>
                  <ApproachNum2 width={189} height={309} />
                </div>
                <div className={'todoProblemContainer'}>
                  <ul className={'todoProblem'}>
                    <p className={'funcTitle'}>{t('fitness.problemTitle')}:</p>
                    {problemArr.map((item, index) => {
                      return <li key={index}>{item.text}</li>
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={'processContainer'}>
            <div>
              <div className={'processSvgContainer'}>
                <ApproachNum3 width={189} height={309} />
              </div>
              <div className={'todoProblemContainer'}>
                <ul className={'todoProblem'}>
                  <p className={'funcTitle'}>{t('fitness.processTitle')}</p>
                  {processArr.map((item, index) => {
                    return <li key={index}>{item.text}</li>
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className={'outProject'}>
            <div className={'outProjectTitle'}>
              <p className={'OutTitle'}>{t('fitness.OutputTitle')}</p>
            </div>
            <div className={'outProjectContent'}>
              <ul className={'outText'}>
                {outputArr.map((item, index) => {
                  return <li key={index}>{item.text}</li>
                })}
              </ul>
              <img src={Fitness} alt="img" />
            </div>
          </div>
          <div className={'FitnessFooterInfo'}>
            <div>
              <p className={'FitnessInfo'}>{t('NDA')}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FitnessCase
