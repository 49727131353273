import React from 'react'
import {
  BackEndSvg,
  ServiceMobile,
  ServiceUi,
  ServiceWeb,
} from '../../../../assets/svg'
import { SvgProps } from '../../../../interfaces/SvgProps'

const TechnoSwitcher = (props: SvgProps) => {
  const { active, onClick1, onClick2, onClick3, onClick4 } = props
  return (
    <div>
      <div className={'serviceSwitcher'}>
        <div className={active === 0 ? 'activeIcon' : 'defaultIcon'}>
          <ServiceWeb onClick={onClick1} />
        </div>
        <div className={active === 1 ? 'activeIcon' : 'defaultIcon'}>
          <ServiceMobile onClick={onClick2} />
        </div>
        <div className={active === 2 ? 'activeIcon' : 'defaultIcon'}>
          <ServiceUi onClick={onClick3} />
        </div>
        <div className={active === 3 ? 'activeIcon' : 'defaultIcon'}>
          <BackEndSvg onClick={onClick4} />
        </div>
      </div>
    </div>
  )
}

export default TechnoSwitcher
