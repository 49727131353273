import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function ServiceLine2_2k(props: SvgProps) {
  const { width, height, className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 1992 273"
    >
      <path stroke="url(#paint0_linear_1650_6807)" d="M0 272h1991.07V.5"/>
      <defs>
        <linearGradient
          id="paint0_linear_1650_6807"
          x1="0"
          x2="2074.03"
          y1="136.248"
          y2="136.248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ServiceLine2_2k

ServiceLine2_2k.defaultProps = {
  width: '1992',
  height: '273',
}
