// modules
import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import * as Scroll from 'react-scroll'
//constants
import { LinkArr } from '../../../../constants'
// interface
import { HomeTextProps } from '../../../../interfaces/HomeTextProps'
// utils
import { HomeTextAnimation } from '../../../../utils/animation/data'
import LinkImg from '../LinkImg'
import Slider from 'react-slick'

const HomeText = ({
  customSlider,
  isLandscape,
  isBigLaptop,
  isMobileScreen,
  setIndex,
  isLaptopWidth,
  setActiveSlide,
}: HomeTextProps) => {
  const { t } = useTranslation()

  const currentSlide = (slideNumber: number) => {
    customSlider.current.slickGoTo(slideNumber)
  }

  return (
    <motion.div
      initial={'hidden'}
      whileInView={'visible'}
      className={'textContainer'}
    >
      <div className={'contentContainer'}>
        <div className={'homeTextContainer'}>
          <motion.div custom={1} variants={HomeTextAnimation}>
            <span
              className={
                isMobileScreen && !isBigLaptop && !isLandscape
                  ? 'logoTitleMob'
                  : isMobileScreen && isBigLaptop && !isLandscape
                  ? 'logoTitleLap'
                  : isMobileScreen && isLandscape
                  ? 'logoTitleLand'
                  : 'logoTitle'
              }
            >
              For<span className={'dreamTitle'}>Dream</span>
            </span>
          </motion.div>
          <motion.div
            className={
              isMobileScreen && !isBigLaptop && !isLandscape
                ? 'descContainerMob'
                : isMobileScreen && isBigLaptop && !isLandscape
                ? 'descContainerBLap'
                : isLandscape && isMobileScreen
                ? 'descContainerLand'
                : 'descContainer'
            }
            custom={2}
            variants={HomeTextAnimation}
          >
            <p
              className={
                isMobileScreen && !isLandscape
                  ? 'textStyleMob'
                  : isMobileScreen && isLandscape
                  ? 'textStyleLand'
                  : 'textStyle'
              }
            >
              {t('home.title')}
            </p>
            <p
              className={
                isMobileScreen && !isLandscape
                  ? 'textStyleMob2'
                  : isMobileScreen && isLandscape
                  ? 'textStyleLand2'
                  : 'textStyle2'
              }
            >
              {t('home.text')}
            </p>
          </motion.div>
        </div>
        <div className={isMobileScreen ? 'svgMainDivMob' : 'svgMainDiv'}>
          {!isMobileScreen || isLandscape ? (
            <div
              className={
                isMobileScreen && !isLandscape && !isLaptopWidth
                  ? 'homeSvgContainerMob'
                  : 'homeSvgContainer'
              }
            >
              {LinkArr.map((item, index) => {
                return (
                  <LinkImg
                    key={index}
                    img={item.img}
                    className={item.className}
                    isMobileScreen={isMobileScreen}
                    isLandscape={isLandscape}
                    path={item.path}
                    onClick={() => {
                      setIndex(item.index)
                      currentSlide(item.id)
                      setActiveSlide(item.id)
                    }}
                    isLaptopWidth={isLaptopWidth}
                  />
                )
              })}
            </div>
          ) : (
            <div
              className={
                !isLaptopWidth ? 'homeSvgContainerMob' : 'homeSvgContainerLap'
              }
            >
              <Slider
                className={
                  !isLaptopWidth ? 'homeSvgContainerMob' : 'homeSvgContainerLap'
                }
                slidesToShow={2.5}
                arrows={false}
              >
                {LinkArr.map((item, index) => {
                  return (
                    <LinkImg
                      isLaptopWidth={isLaptopWidth}
                      key={index}
                      img={item.img}
                      className={item.className}
                      isMobileScreen={isMobileScreen}
                      isLandscape={isLandscape}
                      path={item.path}
                      onClick={() => {
                        setIndex(item.index)
                        currentSlide(item.id)
                        setActiveSlide(item.id)
                      }}
                    />
                  )
                })}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}

export default HomeText
