import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

const ArrowDown = ({ width, height, className }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      className={className}
      viewBox="0 0 41 21"
    >
      <path
        fill="url(#paint0_linear_195_4913)"
        d="M40.017 4.371a2.86 2.86 0 01-1.063 2.226L21.776 20.354a2.856 2.856 0 01-3.628-.01L1.044 6.012A2.861 2.861 0 014.713 1.62l15.279 12.814L35.337 2.13a2.857 2.857 0 014.68 2.241z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_195_4913"
          x1="19.973"
          x2="20.028"
          y1="21"
          y2="0.166"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"></stop>
          <stop offset="1" stopColor="#2033E0"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ArrowDown

ArrowDown.defaultProps = {
  width: 41,
  height: 21,
}
