import styled from '@emotion/styled'

export const ServiceScreen = styled.div`
  padding-left: 2vw;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
