//modules
import React from 'react'
// @ts-ignore
import * as Scroll from 'react-scroll'
// @ts-ignore
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
// utils
import '../../utils/i18next'
//styles
import './style.css'

function NavLinks({ setIndex }: any) {
  let Link = Scroll.Link
  const { t } = useTranslation()

  return (
    <>
      <div className={'mainDiv'}>
        <div className={'linkContainer'}>
          <Link
            onClick={() => setIndex(0)}
            activeClass={'activeHome'}
            smooth={true}
            duration={600}
            className={'HomeNav'}
            to="home"
            spy={true}
          >
            {t('buttons.homeBtn')}
          </Link>
          <Link
            onClick={() => setIndex(1)}
            spy={true}
            smooth={true}
            duration={600}
            activeClass={'activeAbout'}
            className={'AboutNav'}
            to="experience"
          >
            {t('buttons.expBtn')}
          </Link>
          <Link
            onClick={() => setIndex(2)}
            spy={true}
            smooth={true}
            duration={600}
            activeClass={'activeMain'}
            className={'MainNav'}
            to="approach"
          >
            {t('buttons.approachBtn')}
          </Link>
          <Link
            onClick={() => setIndex(3)}
            spy={true}
            smooth={true}
            duration={600}
            activeClass={'activeApproach'}
            className={'ApproachNav'}
            to="service"
          >
            {t('buttons.serviceBtn')}
          </Link>
          <Link
            onClick={() => setIndex(4)}
            spy={true}
            smooth={true}
            duration={600}
            activeClass={'activeProject'}
            className={'ProjectNav'}
            to="ourTeam"
          >
            {t('buttons.TeamBtn')}
          </Link>
          <Link
            onClick={() => setIndex(5)}
            spy={true}
            smooth={true}
            duration={600}
            activeClass={'activeProject'}
            className={'ProjectNav'}
            to="technology"
          >
            {t('buttons.technologyBtn')}
          </Link>
          <Link
            onClick={() => setIndex(6)}
            spy={true}
            smooth={true}
            duration={600}
            activeClass={'activeProject'}
            className={'ProjectNav'}
            to="getProject"
          >
            {t('buttons.ProjectBtn')}
          </Link>
        </div>
      </div>
    </>
  )
}

export default NavLinks
