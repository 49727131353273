import { LayoutSvg, MobSlideSvg, UiSvg, WebSvg } from '../assets/svg'
import { images } from '../assets/image'
import { useTranslation } from 'react-i18next'

// geoLocation
export const geolocationDB =
  'https://geolocation-db.com/json/3a8c2310-2f38-11ed-ba3a-d59b63bcc8c1'
// path
export const position = [
  'goHome',
  'toExp',
  'toApproach',
  'toService',
  'toTeam',
  'toTechno',
  'toProject',
  'footer',
]

export const LinkArr = [
  { index: 3, path: 'service', id: 0, img: UiSvg, className: 'imgLink' },
  { index: 3, path: 'service', id: 1, img: WebSvg, className: 'imgLink2' },
  { index: 3, path: 'service', id: 2, img: MobSlideSvg, className: 'imgLink3' },
  { index: 3, path: 'service', id: 3, img: LayoutSvg, className: 'imgLink4' },
]

export const AvatarArr = [
  { img: images.TeamMember1, slide: 0 },
  { img: images.TeamMember5, slide: 1 },
  { img: images.TeamMember5, slide: 2 },
  { img: images.TeamMember3, slide: 3 },
  { img: images.TeamMember2, slide: 4 },
  { img: images.TeamMember4, slide: 5 },
]

export const ArrowsArr = [
  {
    className: 'aboutArrow1',
    width: 20,
    height: 40,
    mobWidth: 40,
    mobHeight: 80,
  },
  {
    className: 'aboutArrow2',
    width: 20,
    height: 40,
    mobWidth: 40,
    mobHeight: 80,
  },
  {
    className: 'aboutArrow3',
    width: 20,
    height: 40,
    mobWidth: 40,
    mobHeight: 80,
  },
  {
    className: 'aboutArrow4',
    width: 20,
    height: 40,
    mobWidth: 40,
    mobHeight: 80,
  },
]

export const StoreFunArr = [
  { text: 'the ability to view products' },
  { text: 'ability to find products using search and filters\n' },
  { text: 'possibility to order goods and pay through the site\n' },
  { text: 'possibility of feedback via online chat within the site\n' },
  { text: 'create an account' },
  { text: 'getting discounts' },
  { text: 'making a return or exchange through the site' },
]
export const StoreTaskArr = [
  { text: 'design from scratch' },
  { text: 'web design version' },
  { text: 'mobile design version' },
  { text: 'user research' },
]

export const StoreProcessArr = [
  { text: 'conducting a briefing with the client' },
  { text: 'conducting ux research' },
  { text: 'setting priorities' },
  { text: 'setting the main goals of the project' },
  { text: 'interviewing potential users' },
  { text: 'prototyping' },
  { text: 'creating a design system / creating ui kits' },
  { text: 'creating mockups' },
]
