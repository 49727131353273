import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function ServiceUi(props: SvgProps) {
  const { width, height, className, onClick } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 118 118"
      className={className}
      onClick={onClick}
    >
      <rect
        width="113"
        height="113"
        x="2.5"
        y="2.5"
        fill="url(#paint0_linear_599_28534)"
        fillOpacity="0.24"
        stroke="url(#paint1_linear_599_28534)"
        strokeWidth="5"
        rx="2.5"
      />
      <path
        fill="#2332DE"
        d="M47.597 87.047h1.709v6.651c0 .757-.164 1.39-.492 1.9-.329.511-.775.896-1.34 1.156-.56.255-1.188.383-1.88.383-.716 0-1.354-.128-1.914-.383a3.057 3.057 0 01-1.327-1.155c-.319-.51-.478-1.144-.478-1.9v-6.652h1.709v6.651c0 .479.082.873.246 1.183.164.305.397.53.697.677.301.145.657.218 1.067.218.41 0 .763-.072 1.06-.218.3-.146.532-.372.697-.677.164-.31.246-.704.246-1.183v-6.651zm5.407 0V97h-1.716v-9.953h1.716zm6.296 0l-3.876 10.808h-1.292l3.883-10.808H59.3zm6.89 0h1.71v6.651c0 .757-.165 1.39-.493 1.9-.328.511-.775.896-1.34 1.156-.56.255-1.187.383-1.88.383-.715 0-1.353-.128-1.914-.383a3.056 3.056 0 01-1.326-1.155c-.319-.51-.478-1.144-.478-1.9v-6.652h1.709v6.651c0 .479.082.873.246 1.183.164.305.396.53.697.677.3.145.656.218 1.066.218.41 0 .764-.072 1.06-.218.3-.146.533-.372.697-.677.164-.31.246-.704.246-1.183v-6.651zm4.881 0l2.113 3.63 2.112-3.63h1.975l-3.007 4.929L77.347 97H75.35l-2.167-3.698L71.017 97h-2.003l3.09-5.024-3.015-4.93h1.982z"
      />
      <path
        fill="url(#paint2_linear_599_28534)"
        d="M86.133 38.831c3.97 14.819-4.823 30.05-19.642 34.021-14.818 3.97-30.05-4.823-34.02-19.642-3.97-14.818 4.823-30.05 19.642-34.02 14.818-3.971 30.05 4.823 34.02 19.641z"
      />
      <path
        fill="#560FAC"
        fillOpacity="0.51"
        d="M32.318 59.91a5.159 5.159 0 11-10.318 0 5.159 5.159 0 0110.318 0z"
      />
      <path
        fill="#560FAC"
        fillRule="evenodd"
        d="M27.159 64.671a4.762 4.762 0 100-9.524 4.762 4.762 0 000 9.524zm0 .397a5.159 5.159 0 100-10.318 5.159 5.159 0 000 10.318z"
        clipRule="evenodd"
      />
      <path
        fill="#141548"
        d="M52.158 62.29a3.175 3.175 0 11-6.35 0 3.175 3.175 0 016.35 0z"
      />
      <path
        fill="#2332DE"
        d="M75.176 59.512a4.762 4.762 0 11-9.524 0 4.762 4.762 0 019.524 0z"
      />
      <path
        fill="#0E184D"
        fillRule="evenodd"
        d="M70.414 63.877a4.365 4.365 0 100-8.73 4.365 4.365 0 000 8.73zm0 .397a4.762 4.762 0 100-9.524 4.762 4.762 0 000 9.524z"
        clipRule="evenodd"
      />
      <path
        fill="#560FAC"
        d="M88.27 44.83a2.778 2.778 0 11-5.555 0 2.778 2.778 0 015.555 0z"
      />
      <path
        fill="url(#paint3_linear_599_28534)"
        d="M96.999 27.37a5.952 5.952 0 11-11.905 0 5.952 5.952 0 0111.905 0z"
      />
      <path
        fill="#550FAD"
        fillOpacity="0.51"
        d="M25.176 31.735c0-.438.355-.794.793-.794h26.19c.44 0 .795.356.795.794v1.587a.794.794 0 01-.794.794H25.97a.794.794 0 01-.794-.794v-1.587z"
      />
      <path
        fill="#3327CF"
        fillRule="evenodd"
        d="M52.16 31.338H25.97a.397.397 0 00-.397.397v1.587c0 .22.177.397.396.397h26.19c.22 0 .398-.178.398-.397v-1.587a.397.397 0 00-.397-.397zm-26.19-.397a.794.794 0 00-.794.794v1.587c0 .439.355.794.793.794h26.19a.794.794 0 00.795-.794v-1.587a.794.794 0 00-.794-.794H25.97z"
        clipRule="evenodd"
      />
      <path
        fill="#550FAD"
        fillOpacity="0.51"
        d="M29.938 36.497c0-.438.355-.794.793-.794H52.16c.438 0 .793.355.793.794v1.587a.794.794 0 01-.793.794H30.73a.794.794 0 01-.794-.794v-1.587z"
      />
      <path
        fill="#3327CF"
        fillRule="evenodd"
        d="M52.16 36.1H30.73a.397.397 0 00-.397.397v1.587c0 .22.178.397.397.397H52.16c.219 0 .397-.178.397-.397v-1.587a.397.397 0 00-.397-.397zm-21.429-.397a.794.794 0 00-.794.794v1.587c0 .438.356.794.794.794H52.16a.794.794 0 00.793-.794v-1.587a.794.794 0 00-.793-.794H30.73z"
        clipRule="evenodd"
      />
      <path
        fill="#550FAD"
        fillOpacity="0.51"
        d="M37.078 41.258c0-.438.355-.793.794-.793h14.285c.439 0 .794.355.794.793v1.588a.794.794 0 01-.794.793H37.873a.794.794 0 01-.794-.793v-1.587z"
      />
      <path
        fill="#3327CF"
        fillRule="evenodd"
        d="M52.157 40.862H37.873a.397.397 0 00-.397.396v1.588c0 .219.178.397.397.397h14.285c.22 0 .397-.178.397-.397v-1.587a.397.397 0 00-.397-.397zm-14.285-.397a.794.794 0 00-.794.793v1.588c0 .438.355.793.794.793h14.285a.794.794 0 00.794-.793v-1.587a.794.794 0 00-.794-.794H37.873z"
        clipRule="evenodd"
      />
      <path
        fill="#550FAD"
        fillOpacity="0.51"
        d="M42.637 46.02c0-.438.355-.793.793-.793h8.73c.439 0 .794.355.794.794v1.587a.794.794 0 01-.794.794h-8.73a.794.794 0 01-.793-.794v-1.587z"
      />
      <path
        fill="#3327CF"
        fillRule="evenodd"
        d="M52.16 45.624h-8.73a.397.397 0 00-.397.397v1.587c0 .22.178.397.397.397h8.73c.22 0 .397-.178.397-.397v-1.587a.397.397 0 00-.397-.397zm-8.73-.397a.794.794 0 00-.793.794v1.587c0 .438.355.794.793.794h8.73a.794.794 0 00.794-.794v-1.587a.794.794 0 00-.794-.794h-8.73z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="paint0_linear_599_28534"
          x1="0"
          x2="122.917"
          y1="59.001"
          y2="59.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
        <linearGradient
          id="paint1_linear_599_28534"
          x1="0"
          x2="122.917"
          y1="59.001"
          y2="59.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
        <linearGradient
          id="paint2_linear_599_28534"
          x1="32.471"
          x2="88.369"
          y1="53.211"
          y2="38.233"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
        <linearGradient
          id="paint3_linear_599_28534"
          x1="85.094"
          x2="97.495"
          y1="27.37"
          y2="27.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
export default ServiceUi

ServiceUi.defaultProps = {
  width: 118,
  height: 118,
}
