//Modules
import React, { memo, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'
//components
import { ChildPage, MChildPage } from '../ChildPage'
import NavigationText from '../../components/navigationText'
import MobileSwitcher from '../Service/components/MobileSwitcher'
// utils
import { lineAnimation } from '../../utils/animation/data'
// interface
import { ScreenProps } from '../../interfaces/ScreenProps'
// assets
import {
  BackEndSvg,
  ServiceMobile,
  ServiceUi,
  ServiceWeb,
  TechnoLine1,
  TechnoLine2,
} from '../../assets/svg'
//style
import './style.css'
import { ServiceScreen } from '../Service/style'
import TechnoSwitcher from './components/TechnoSwither'

function Technologies(props: ScreenProps) {
  const {
    isMobileScreen,
    isBigLaptop,
    isBigScreen,
    isLaptopWidth,
    isSmallScreen,
    isLandscape,
  } = props
  const { t } = useTranslation()
  const customSlider = useRef<any>()
  const [active, setActive] = useState<number>(0)
  const TechnoWebArr = t('technologies.web', { returnObjects: true })
  const TechnoMobileArr = t('technologies.mobile', { returnObjects: true })
  const TechnoUiArr = t('technologies.UI', { returnObjects: true })
  const TechnoBackArr = t('technologies.backend', { returnObjects: true })

  const slideUi = () => {
    setActive(0)
    // @ts-ignore
    customSlider.current.slickGoTo(0)
  }
  const slideWeb = () => {
    setActive(1)
    // @ts-ignore
    customSlider.current.slickGoTo(1)
  }
  const slideMobile = () => {
    setActive(2)
    // @ts-ignore
    customSlider.current.slickGoTo(2)
  }
  const slideLayout = () => {
    setActive(3)
    // @ts-ignore
    customSlider.current.slickGoTo(3)
  }

  return (
    <ServiceScreen>
      <div className={isMobileScreen ? 'technoSliderMob' : 'serviceSlider'}>
        <motion.div
          initial={'hidden'}
          whileInView={'visible'}
          viewport={{ amount: 0.5 }}
        >
          {isMobileScreen ? (
            <NavigationText
              animation={{ amount: 0.5, once: true }}
              isLandscape={isLandscape}
              isBigLaptop={isBigLaptop}
              isMobileScreen={isMobileScreen}
              isLaptopWidth={isLaptopWidth}
              classLaptop={'navigationServiceLaptop'}
              classBigLaptop={'navigationServiceBLaptop'}
              classMobile={'navigationService'}
              textNavigation={t('buttons.technologyBtn')}
              classLandScape={'navigationServiceLand'}
            />
          ) : null}
          <Slider
            swipe={false}
            arrows={false}
            ref={customSlider}
            className={
              isMobileScreen && !isLaptopWidth && !isLandscape
                ? 'sliderTechnoMob'
                : isMobileScreen && isLaptopWidth && !isLandscape
                ? 'sliderTechnoLap'
                : isMobileScreen && isLandscape
                ? 'sliderTechnoLand'
                : 'sliderTechno'
            }
            draggable={false}
          >
            <MChildPage
              isLandscape={isLandscape}
              isMobileScreen={isMobileScreen}
              isBigLaptop={isBigLaptop}
              isLaptopWidth={isLaptopWidth}
              variants={lineAnimation}
              custom={1}
              line1={
                isBigScreen ? (
                  <TechnoLine1 className={'technoLine1'} width={1100} />
                ) : isSmallScreen ? (
                  <TechnoLine1 className={'technoLine1'} width={900} />
                ) : (
                  <TechnoLine1 className={'technoLine1'} />
                )
              }
              line2={
                isBigScreen ? (
                  <TechnoLine2 className={'TechnoLine3'} width={1100} />
                ) : isSmallScreen ? (
                  <TechnoLine2 className={'TechnoLine3'} width={900} />
                ) : (
                  <TechnoLine2 className={'TechnoLine3'} />
                )
              }
              text={TechnoWebArr}
              image={
                <ServiceWeb
                  width={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : isBigLaptop && !isLandscape
                      ? 350
                      : isMobileScreen && isLandscape
                      ? 350
                      : 312
                  }
                  height={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : (isBigLaptop && !isLandscape) ||
                        (isMobileScreen && isLandscape)
                      ? 350
                      : 312
                  }
                />
              }
            />
            <ChildPage
              isLandscape={isLandscape}
              isMobileScreen={isMobileScreen}
              isBigLaptop={isBigLaptop}
              isLaptopWidth={isLaptopWidth}
              line1={
                isBigScreen ? (
                  <TechnoLine1 className={'technoLine1'} width={1100} />
                ) : isSmallScreen ? (
                  <TechnoLine1 className={'technoLine1'} width={900} />
                ) : (
                  <TechnoLine1 className={'technoLine1'} />
                )
              }
              line2={
                isBigScreen ? (
                  <TechnoLine2 className={'TechnoLine3'} width={1100} />
                ) : isSmallScreen ? (
                  <TechnoLine2 className={'TechnoLine3'} width={900} />
                ) : (
                  <TechnoLine2 className={'TechnoLine3'} />
                )
              }
              text={TechnoMobileArr}
              image={
                <ServiceMobile
                  width={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : isBigLaptop && !isLandscape
                      ? 350
                      : isMobileScreen && isLandscape
                      ? 350
                      : 312
                  }
                  height={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : (isBigLaptop && !isLandscape) ||
                        (isMobileScreen && isLandscape)
                      ? 350
                      : 312
                  }
                />
              }
            />
            <ChildPage
              isLandscape={isLandscape}
              isMobileScreen={isMobileScreen}
              isBigLaptop={isBigLaptop}
              isLaptopWidth={isLaptopWidth}
              line1={
                isBigScreen ? (
                  <TechnoLine1 className={'technoLine1'} width={1100} />
                ) : isSmallScreen ? (
                  <TechnoLine1 className={'technoLine1'} width={900} />
                ) : (
                  <TechnoLine1 className={'technoLine1'} />
                )
              }
              line2={
                isBigScreen ? (
                  <TechnoLine2 className={'TechnoLine3'} width={1100} />
                ) : isSmallScreen ? (
                  <TechnoLine2 className={'TechnoLine3'} width={900} />
                ) : (
                  <TechnoLine2 className={'TechnoLine3'} />
                )
              }
              text={TechnoUiArr}
              image={
                <ServiceUi
                  width={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : isBigLaptop && !isLandscape
                      ? 350
                      : isMobileScreen && isLandscape
                      ? 350
                      : 312
                  }
                  height={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : (isBigLaptop && !isLandscape) ||
                        (isMobileScreen && isLandscape)
                      ? 350
                      : 312
                  }
                />
              }
            />
            <ChildPage
              isLandscape={isLandscape}
              isMobileScreen={isMobileScreen}
              isBigLaptop={isBigLaptop}
              isLaptopWidth={isLaptopWidth}
              line1={
                isBigScreen ? (
                  <TechnoLine1 className={'technoLine1'} width={1100} />
                ) : isSmallScreen ? (
                  <TechnoLine1 className={'technoLine1'} width={900} />
                ) : (
                  <TechnoLine1 className={'technoLine1'} />
                )
              }
              line2={
                isBigScreen ? (
                  <TechnoLine2 className={'TechnoLine3'} width={1100} />
                ) : isSmallScreen ? (
                  <TechnoLine2 className={'TechnoLine3'} width={900} />
                ) : (
                  <TechnoLine2 className={'TechnoLine3'} />
                )
              }
              text={TechnoBackArr}
              image={
                <BackEndSvg
                  width={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : isBigLaptop && !isLandscape
                      ? 350
                      : isMobileScreen && isLandscape
                      ? 350
                      : 312
                  }
                  height={
                    isMobileScreen && !isLaptopWidth && !isLandscape
                      ? 600
                      : isMobileScreen &&
                        isLaptopWidth &&
                        !isBigLaptop &&
                        !isLandscape
                      ? 450
                      : (isBigLaptop && !isLandscape) ||
                        (isMobileScreen && isLandscape)
                      ? 350
                      : 312
                  }
                />
              }
            />
          </Slider>
          <div
            className={
              isMobileScreen && !isLandscape
                ? 'technoBtnCon && !isLandscapetainerMob'
                : isMobileScreen && isLandscape
                ? 'technoBtnContainerLand'
                : 'serviceBtnContainer'
            }
          >
            {!isMobileScreen ? (
              <TechnoSwitcher
                active={active}
                onClick1={slideUi}
                onClick2={slideWeb}
                onClick3={slideMobile}
                onClick4={slideLayout}
              />
            ) : (
              <MobileSwitcher
                isLandscape={isLandscape}
                onClick1={slideUi}
                onClick2={slideWeb}
                onClick3={slideMobile}
                onClick4={slideLayout}
                active={active}
                serviceLandSwitcher={'technoLandSwitch'}
              />
            )}
          </div>
        </motion.div>
      </div>
    </ServiceScreen>
  )
}

export default memo(Technologies)
