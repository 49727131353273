// modules
import React from 'react'
// @ts-ignore
import * as Scroll from 'react-scroll'
//interface
import { ScreenProps } from '../../../../interfaces/ScreenProps'
// assets
import { LogoSvg } from '../../../../assets/svg'

function HeaderMob({ isLaptopWidth, isLandscape, headerClick }: ScreenProps) {
  let Link = Scroll.Link

  return (
    <div className={!isLandscape ? 'logoContainerMob' : 'logoContainerLand'}>
      <div className={'imgDiv'}>
        <Link to={'home'} smooth={true} onClick={headerClick}>
          <LogoSvg
            width={!isLaptopWidth && !isLandscape ? 110 : isLandscape ? 65 : 80}
            height={
              !isLaptopWidth && !isLandscape ? 130 : isLandscape ? 85 : 100
            }
          />
        </Link>
      </div>
    </div>
  )
}
export default HeaderMob
