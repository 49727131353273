// modules
import React, { memo, useState } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
// components
import NavigationText from '../../components/navigationText'
import ApproachCart from './components/ApproachCart'
import ApproachSvg from './components/ApproachSvg'
// utils
import { TextApproachAnim } from '../../utils/animation/data'
import '../../utils/i18next'
// assets
import {
  ApproachNum1,
  ApproachNum2,
  ApproachNum3,
  ApproachNum4,
} from '../../assets/svg'
import { images } from '../../assets/image'
// styles
import './style.css'
import { AppPage } from './style'
import { ScreenProps } from '../../interfaces/ScreenProps'
import ApproachNum1Mob from '../../assets/svg/ApproachNum/ApproachNum1Mob'

function Approach(props: ScreenProps) {
  const {
    isMobileScreen,
    isLaptopWidth,
    isBigLaptop,
    isLittleHeight,
    isBigHeight,
    isLandscape,
  } = props
  const { t } = useTranslation()
  const [circle, setCircle] = useState<any>(['circle1'])

  return (
    <AppPage>
      {isMobileScreen ? (
        <NavigationText
          animation={{ amount: 0.5 }}
          isLandscape={isLandscape}
          isLaptopWidth={isLaptopWidth}
          isMobileScreen={isMobileScreen}
          isBigLaptop={isBigLaptop}
          classLaptop={'navigationAppLap'}
          classBigLaptop={'navigationAppBLap'}
          classMobile={'navigationApp'}
          textNavigation={t('buttons.approachBtn')}
          classLandScape={'navigationAppLand'}
        />
      ) : null}
      <motion.div
        className={
          isMobileScreen && !isLandscape
            ? 'approachContentMob'
            : isMobileScreen && isLandscape
            ? 'approachContentLand'
            : 'approachContent'
        }
        initial={'hidden'}
        whileInView={'visible'}
        viewport={{ amount: 0.8 }}
      >
        <motion.div
          custom={1}
          variants={!isMobileScreen ? TextApproachAnim : undefined}
          className={'appContentContainer'}
        >
          {circle == 'circle1' ? (
            <ApproachCart
              isMobileScreen={isMobileScreen}
              isLandscape={isLandscape}
              isLaptopWidth={isLaptopWidth}
              isBigLaptop={isBigLaptop}
              Image={
                !isMobileScreen ? (
                  <ApproachNum1 className={'ApproachNum1'} />
                ) : (
                  <img
                    src={images.Num1}
                    className={
                      !isLaptopWidth && !isLandscape
                        ? 'apprachNum'
                        : isLandscape
                        ? 'apprachNumLand1'
                        : 'apprachNumLap'
                    }
                    alt="image"
                  />
                )
              }
              text={t('approach.slide1')}
            />
          ) : circle == 'circle2' ? (
            <ApproachCart
              isMobileScreen={isMobileScreen}
              isLandscape={isLandscape}
              isLaptopWidth={isLaptopWidth}
              isBigLaptop={isBigLaptop}
              Image={
                !isMobileScreen ? (
                  <ApproachNum2
                    className={
                      isLandscape && isMobileScreen
                        ? 'ApproachNum1Land'
                        : 'ApproachNum1'
                    }
                  />
                ) : (
                  <img
                    src={images.Num2}
                    className={
                      !isLaptopWidth && !isLandscape
                        ? 'apprachNum'
                        : isLandscape
                        ? 'apprachNumLand'
                        : 'apprachNumLap'
                    }
                    alt="image"
                  />
                )
              }
              text={t('approach.slide2')}
            />
          ) : circle == 'circle3' ? (
            <ApproachCart
              isMobileScreen={isMobileScreen}
              isLandscape={isLandscape}
              isLaptopWidth={isLaptopWidth}
              isBigLaptop={isBigLaptop}
              Image={
                !isMobileScreen ? (
                  <ApproachNum3
                    className={
                      isLandscape && isMobileScreen
                        ? 'ApproachNum1Land'
                        : 'ApproachNum1'
                    }
                  />
                ) : (
                  <img
                    src={images.Num3}
                    className={
                      !isLaptopWidth && !isLandscape
                        ? 'apprachNum'
                        : isLandscape
                        ? 'apprachNumLand'
                        : 'apprachNumLap'
                    }
                    alt="image"
                  />
                )
              }
              text={t('approach.slide3')}
            />
          ) : circle == 'circle4' ? (
            <ApproachCart
              isMobileScreen={isMobileScreen}
              isLandscape={isLandscape}
              isLaptopWidth={isLaptopWidth}
              isBigLaptop={isBigLaptop}
              Image={
                !isMobileScreen ? (
                  <ApproachNum4
                    className={
                      isLandscape && isMobileScreen
                        ? 'ApproachNum1Land'
                        : 'ApproachNum1'
                    }
                  />
                ) : (
                  <img
                    src={images.Num4}
                    className={
                      !isLaptopWidth && !isLandscape
                        ? 'apprachNum'
                        : isLandscape
                        ? 'apprachNumLand'
                        : 'apprachNumLap'
                    }
                    alt="image"
                  />
                )
              }
              text={t('approach.slide4')}
            />
          ) : null}
        </motion.div>
        <ApproachSvg
          isLandscape={isLandscape}
          isMobileScreen={isMobileScreen}
          circle={circle}
          setCircle={setCircle}
          isBigHeight={isBigHeight}
          isLaptopWidth={isLaptopWidth}
          isLittleHeight={isLittleHeight}
        />
      </motion.div>
    </AppPage>
  )
}

export default memo(Approach)
