import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

const InputSvg = ({ width, height, className, color }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 21 24"
    >
      <path
        fill={color}
        d="M1.725 12.776l9.05-9.051a4.8 4.8 0 116.79 6.789L6.815 21.26a2.4 2.4 0 01-3.393-3.394l9.616-9.616a.8.8 0 10-1.131-1.132l-9.616 9.616a4 4 0 005.656 5.657l10.749-10.747a6.401 6.401 0 00-9.051-9.053L.592 11.645a.8.8 0 001.131 1.131h.002z"
      ></path>
    </svg>
  )
}

export default InputSvg

InputSvg.defaultProps = {
  width: 21,
  height: 24,
  color: '#fff',
}
