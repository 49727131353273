import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function ApproachLineShort(props: SvgProps) {
  const {
    className,
    onClick4,
    radius4,
    onClick1,
    radius1,
    onClick2,
    radius2,
    onClick3,
    radius3,
    width,
    height,
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 308 780"
    >
      <path stroke="#fff" d="M50 0v185.364h208v478.178H50V765" />
      <circle
        className={radius3 === 50 ? 'circle' : 'circleAnim'}
        onClick={onClick3}
        cx="258"
        cy="415"
        r={radius3}
        fill="url(#paint0_linear_1650_6777)"
        stroke={radius3 === 50 ? 'white' : 'transparent'}
      />
      <circle
        className={radius2 === 50 ? 'circle' : 'circleAnim'}
        onClick={onClick2}
        cx="258"
        cy="192"
        r={radius2}
        fill="url(#paint1_linear_1650_6777)"
        stroke={radius2 === 50 ? 'white' : 'transparent'}
      />
      <circle
        className={radius4 === 50 ? 'circle' : 'circleAnim'}
        onClick={onClick4}
        cx="258"
        cy="664"
        r={radius4}
        fill="url(#paint2_linear_1650_6777)"
        stroke={radius4 === 50 ? 'white' : 'transparent'}
      />
      <circle
        className={radius1 === 50 ? 'circle' : 'circleAnim'}
        onClick={onClick1}
        cx="50"
        cy="192"
        r={radius1}
        fill="url(#paint3_linear_1650_6777)"
        stroke={radius1 === 50 ? 'white' : 'transparent'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1650_6777"
          x1="233"
          x2="285.083"
          y1="415"
          y2="415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1650_6777"
          x1="233"
          x2="285.083"
          y1="192"
          y2="192"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1650_6777"
          x1="233"
          x2="285.083"
          y1="664"
          y2="664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1650_6777"
          x1="0"
          x2="104.167"
          y1="192.001"
          y2="192.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default ApproachLineShort

ApproachLineShort.defaultProps = {
  width: 308,
  height: 780,
}
