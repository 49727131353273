//modules
import React from 'react'
import { motion } from 'framer-motion'
import { HomeTextAnimation } from '../../utils/animation/data'
//interface
import { NavigationProps } from '../../interfaces/NavigationProps'

const NavigationText = (props: NavigationProps) => {
  const {
    classLaptop,
    classBigLaptop,
    classMobile,
    textNavigation,
    isLaptopWidth,
    isMobileScreen,
    isBigLaptop,
    isLandscape,
    classLandScape,
    animation,
  } = props

  return (
    <div>
      <motion.div
        className={
          isLaptopWidth && isMobileScreen && !isBigLaptop && !isLandscape
            ? classLaptop
            : isBigLaptop && !isLandscape
            ? classBigLaptop
            : isMobileScreen && isLandscape
            ? classLandScape
            : classMobile
        }
        initial={'hidden'}
        whileInView={'visible'}
        viewport={animation}
      >
        <motion.p custom={1} variants={HomeTextAnimation}>
          {textNavigation}
        </motion.p>
      </motion.div>
    </div>
  )
}

export default NavigationText
