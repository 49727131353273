import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function ServiceLine2(props: SvgProps) {
  const { width, height, className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 1120 274"
    >
      <path
        fill="#2B38B0"
        fillRule="evenodd"
        d="M1114.5 0a5.501 5.501 0 011 10.91V274H0v-1h1114.5V11c-3.04 0-5.5-2.462-5.5-5.5s2.46-5.5 5.5-5.5z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ServiceLine2

ServiceLine2.defaultProps = {
  width: 1120,
  height: 274,
}
