//modules
import React from 'react'
//components
import { ApproachNum1, ApproachNum2, ApproachNum3 } from '../../../assets/svg'
import ChildTitle from './components/ChildTitle'
//interface
import { ChildCaseProp } from '../../../interfaces/ChildCaseProp'

const ChildCase = ({
  title,
  text,
  functionArr,
  taskArr,
  OutProjectArr,
  image,
  onClick,
  language,
  processTitle,
  functionTitle,
  isMobileScreen,
  taskTitle,
  processArr,
  outputTitle,
  headerClick,
  isLaptopWidth,
}: ChildCaseProp) => {
  return (
    <div className={!isMobileScreen ? 'mainFitness' : 'mainFitnessMob'}>
      <ChildTitle
        isLaptopWidth={isLaptopWidth}
        isMobileScreen={isMobileScreen}
        title={title}
        text={text}
        OutProjectArr={OutProjectArr}
        image={image}
        onClick={onClick}
        language={language}
        processTitle={processTitle}
        headerClick={headerClick}
      >
        <div
          className={
            isMobileScreen ? 'functionContainerMob' : 'functionContainer'
          }
        >
          <div
            className={
              !isMobileScreen ? 'caseSvgContainer' : 'caseSvgContainerMob'
            }
          >
            <ApproachNum1
              width={!isMobileScreen ? 189 : 289}
              height={!isMobileScreen ? 309 : 409}
              className={isMobileScreen ? 'svgCase' : ''}
            />
          </div>
          <div className={'todoContainer'}>
            <div
              className={
                isMobileScreen ? 'todoChildContainerMob' : 'todoChildContainer'
              }
            >
              <ul
                className={
                  isMobileScreen ? 'todoChildFunctionMob' : 'todoChildFunction'
                }
              >
                <p className={'funcTitle'}>{functionTitle}</p>
                {functionArr.map((item, index) => {
                  return <li key={index}>{item.text}</li>
                })}
              </ul>
            </div>
          </div>
        </div>
        <div
          className={
            isMobileScreen
              ? 'mainChildProblemContainerMob'
              : 'mainChildProblemContainer'
          }
        >
          <div
            className={
              isMobileScreen
                ? 'problemChildContainerMob'
                : 'problemChildContainer'
            }
          >
            <div>
              <div
                className={
                  isMobileScreen
                    ? 'problemSvgContainerMob'
                    : 'problemSvgContainer'
                }
              >
                <ApproachNum2
                  className={isMobileScreen ? 'svgCase' : ''}
                  width={!isMobileScreen ? 189 : 289}
                  height={!isMobileScreen ? 309 : 409}
                />
              </div>
              <div
                className={
                  isMobileScreen
                    ? 'todoProblemChildContainerMob'
                    : 'todoProblemChildContainer'
                }
              >
                <ul
                  className={isMobileScreen ? 'todoProblemMob' : 'todoProblem'}
                >
                  <p className={'funcTitle'}>{taskTitle}</p>
                  {taskArr.map((item, index) => {
                    return <li key={index}>{item.text}</li>
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {processArr && (
          <div
            className={
              isMobileScreen
                ? 'mainChildProblemContainerMob'
                : 'mainChildProblemContainer'
            }
          >
            <div
              className={
                isMobileScreen
                  ? 'problemChildContainerMob'
                  : 'problemChildContainer'
              }
            >
              <div>
                <div
                  className={
                    isMobileScreen
                      ? 'problemSvgContainerMob'
                      : 'problemSvgContainer'
                  }
                >
                  <ApproachNum3
                    className={isMobileScreen ? 'svgCase' : ''}
                    width={!isMobileScreen ? 189 : 289}
                    height={!isMobileScreen ? 309 : 409}
                  />
                </div>
                <div
                  className={
                    isMobileScreen
                      ? 'todoProblemChildContainerMob'
                      : 'todoProblemChildContainer'
                  }
                >
                  <ul
                    className={
                      isMobileScreen ? 'todoProblemMob' : 'todoProblem'
                    }
                  >
                    <p className={'funcTitle'}>{outputTitle}</p>
                    {processArr.map((item, index) => {
                      return <li key={index}>{item.text}</li>
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </ChildTitle>
    </div>
  )
}

export default ChildCase
