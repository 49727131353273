//modules
import React, { memo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
//components
import InputMobScreen from '../../components/inputMobScreen'
import NavigationText from '../../components/navigationText'
import HomeText from './components/HomeText'
import HomeImg from './components/HomeImg'
import Modal from '../../components/popUp'
// utils
import '../../utils/i18next'
// interface
import { ScreenProps } from '../../interfaces/ScreenProps'
//style
import { HomePage } from './style'
import './style.css'

function Home(props: ScreenProps) {
  const {
    isMobileScreen,
    isBigLaptop,
    isLaptopWidth,
    isLandscape,
    customSlider,
    setIndex,
    setActiveSlide,
    language,
  } = props
  const { t } = useTranslation()
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [inputScreen, setInputScreen] = useState<boolean>(false)
  const isSmallHeight = useMediaQuery({ maxDeviceHeight: 720 })

  return (
    <HomePage>
      {isMobileScreen && !inputScreen ? (
        <NavigationText
          animation={{ amount: 0.5 }}
          isLandscape={isLandscape}
          classLaptop={'navigationTextLaptop'}
          classBigLaptop={'navigationTextBLaptop'}
          classMobile={'navigationText'}
          textNavigation={t('buttons.homeBtn')}
          isBigLaptop={isBigLaptop}
          isMobileScreen={isMobileScreen}
          isLaptopWidth={isLaptopWidth}
          classLandScape={'navigationTextLand'}
        />
      ) : null}
      <div
        className={
          isMobileScreen && !isBigLaptop && !isLandscape
            ? 'homeMob'
            : isMobileScreen && isBigLaptop && !isLandscape
            ? 'homeBLap'
            : isMobileScreen && isLandscape
            ? 'homeLand'
            : 'baba'
        }
      >
        {isMobileScreen ? (
          <div
            className={
              isBigLaptop && !isSmallHeight
                ? 'navigateLap'
                : isSmallHeight
                ? 'navigateSMob'
                : 'navigate'
            }
            id={isLandscape ? '' : 'inputScreen'}
          />
        ) : null}
        <div>
          <HomeText
            isLaptopWidth={isLaptopWidth}
            language={language}
            setActiveSlide={setActiveSlide}
            customSlider={customSlider}
            isLandscape={isLandscape}
            setModalActive={setModalActive}
            setInputScreen={setInputScreen}
            isBigLaptop={isBigLaptop}
            isMobileScreen={isMobileScreen}
            setIndex={setIndex}
          />
          <HomeImg
            isLaptopWidth={isLaptopWidth}
            isLandscape={isLandscape}
            isBigLaptop={isBigLaptop}
            isMobileScreen={isMobileScreen}
          />
        </div>
      </div>
      <Modal active={modalActive} setActive={setModalActive} />
    </HomePage>
  )
}

export default memo(Home)
