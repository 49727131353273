import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function ServiceWeb(props: SvgProps) {
  const { width, height, onClick } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 118 118"
      onClick={onClick}
    >
      <rect
        width="113"
        height="113"
        x="2.5"
        y="2.5"
        fill="url(#paint0_linear_599_28473)"
        fillOpacity="0.24"
        stroke="url(#paint1_linear_599_28473)"
        strokeWidth="5"
        rx="2.5"
      />
      <path
        fill="#2332DE"
        fillRule="evenodd"
        d="M84.5 21.654H30.501a3.687 3.687 0 00-3.692 3.681v30.831a3.687 3.687 0 003.692 3.681H84.5a3.687 3.687 0 003.692-3.68V25.334a3.687 3.687 0 00-3.692-3.681zm-53.998-1.38c-2.803 0-5.076 2.266-5.076 5.061v30.831c0 2.796 2.273 5.062 5.076 5.062H84.5c2.804 0 5.077-2.266 5.077-5.062v-30.83c0-2.796-2.273-5.063-5.077-5.063H30.502z"
        clipRule="evenodd"
      />
      <path
        fill="#2332DE"
        d="M51.883 61.228h11.235v11.936c0 1.014-.805 1.836-1.797 1.836h-7.64c-.993 0-1.798-.822-1.798-1.836V61.228zM25.79 55.066h63.424l-1.632 4.712H27.888l-2.099-4.712z"
      />
      <path
        fill="#560FAC"
        fillOpacity="0.51"
        stroke="#560FAC"
        d="M34.625 17.512h5.161v24.732h-5.161V17.512z"
      />
      <path
        fill="#560FAC"
        d="M48.984 36.945h5.8V49.63h-5.8V36.945zM63.844 27.16h12.685v12.685H63.844V27.16z"
      />
      <path
        fill="#560FAC"
        fillOpacity="0.51"
        stroke="#560FAC"
        d="M73.766 41.433h6.61v6.248h-6.61v-6.248z"
      />
      <path
        fill="#560FAC"
        fillRule="evenodd"
        d="M40.632 17.471h-6.855c-.757 0-1.37.617-1.37 1.378v28.944c0 .76.613 1.378 1.37 1.378h6.855c.757 0 1.37-.617 1.37-1.378V18.849c0-.76-.613-1.378-1.37-1.378zm-6.855-.46c-1.01 0-1.828.823-1.828 1.838v28.944c0 1.015.819 1.837 1.828 1.837h6.855c1.01 0 1.828-.822 1.828-1.837V18.849a1.833 1.833 0 00-1.828-1.837h-6.855zM55.491 32.691h-6.855c-.757 0-1.37.615-1.37 1.374v13.734c0 .758.613 1.373 1.37 1.373h6.855c.757 0 1.371-.615 1.371-1.373V34.065c0-.759-.614-1.374-1.37-1.374zm-6.855-.458a1.83 1.83 0 00-1.827 1.832v13.734a1.83 1.83 0 001.828 1.831h6.854a1.83 1.83 0 001.828-1.831V34.065a1.83 1.83 0 00-1.828-1.832h-6.855zM83.03 26.17H64.23c-.76 0-1.376.618-1.376 1.38v20.24c0 .762.616 1.38 1.376 1.38H83.03c.76 0 1.376-.618 1.376-1.38V27.55c0-.762-.616-1.38-1.376-1.38zm-18.801-.46a1.837 1.837 0 00-1.834 1.84v20.24c0 1.017.82 1.84 1.834 1.84H83.03a1.837 1.837 0 001.834-1.84V27.55c0-1.016-.821-1.84-1.834-1.84H64.229z"
        clipRule="evenodd"
      />
      <path
        fill="url(#paint2_linear_599_28473)"
        d="M59.699 57.874a2.197 2.197 0 11-4.394 0 2.197 2.197 0 014.394 0z"
      />
      <path
        fill="#2332DE"
        d="M45.507 94.765l1.982-7.718h1.04l-.117 1.99L46.293 97h-1.08l.294-2.235zm-1.484-7.718l1.607 7.656.143 2.297h-1.148l-2.304-9.953h1.702zm6.754 7.636l1.586-7.636h1.71L51.768 97H50.62l.157-2.317zm-1.845-7.636l1.962 7.738L51.18 97H50.1l-2.085-7.964-.11-1.99h1.026zm12.926 8.593V97h-5.284v-1.36h5.284zm-4.798-8.593V97h-1.716v-9.953h1.716zm4.108 4.156v1.34h-4.594v-1.34h4.594zm.656-4.156v1.367h-5.25v-1.367h5.25zm5.168 5.455h-2.536l-.014-1.251h2.215c.374 0 .69-.055.95-.164.265-.114.465-.276.602-.485.137-.215.205-.472.205-.773 0-.333-.064-.604-.191-.813a1.096 1.096 0 00-.588-.458c-.26-.096-.593-.144-.998-.144h-1.661V97H63.26v-9.953h3.377c.547 0 1.034.052 1.463.157.433.105.8.269 1.1.492.305.219.536.497.69.834.16.338.24.739.24 1.203 0 .41-.098.786-.294 1.128a2.237 2.237 0 01-.868.828c-.383.214-.86.341-1.429.382l-.547.431zM66.917 97h-3.001l.773-1.36h2.228c.387 0 .71-.064.97-.192a1.34 1.34 0 00.582-.54c.132-.232.198-.503.198-.813 0-.324-.057-.604-.17-.841a1.178 1.178 0 00-.54-.554c-.247-.132-.568-.198-.965-.198h-1.928l.014-1.251h2.509l.39.472c.546.018.995.139 1.346.362.356.223.62.513.793.868.173.356.26.738.26 1.149 0 .633-.139 1.164-.417 1.592-.273.429-.668.755-1.183.978-.515.219-1.134.328-1.859.328z"
      />
      <defs>
        <linearGradient
          id="paint0_linear_599_28473"
          x1="0"
          x2="122.917"
          y1="59.001"
          y2="59.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
        <linearGradient
          id="paint1_linear_599_28473"
          x1="0"
          x2="122.917"
          y1="59.001"
          y2="59.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
        <linearGradient
          id="paint2_linear_599_28473"
          x1="55.305"
          x2="59.882"
          y1="57.874"
          y2="57.874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ServiceWeb

ServiceWeb.defaultProps = {
  width: 118,
  height: 118,
}
