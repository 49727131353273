//modules
import React from 'react'

const OurTeamComp = ({ teamArray }: any) => {
  return (
    <div className={'teamMainContainer'}>
      {teamArray.map((item: any, index: number) => {
        return (
          <div key={index} className={'teamCard'}>
            <img src={item.img} alt="member" className={'teamMember'} />
            <div className={'hoverTextContainer'}>
              <p className={'titleMember'}>{item.titleTeam}</p>
              <p className={'memberName'}>{item.nameMember}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default OurTeamComp
