import React from 'react'
import { SvgProps } from '../../../interfaces/SvgProps'

function ApproachNum3(props: SvgProps) {
  const { width, height, className, onClick } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      fill="none"
      viewBox="0 0 283 458"
    >
      <path
        fill="url(#paint0_linear_599_27598)"
        d="M91.324 201.061h40.283c19.735 0 36.011-3.255 48.828-9.766 13.021-6.713 22.685-15.767 28.992-27.16 6.51-11.597 9.765-24.618 9.765-39.063 0-17.09-2.848-31.433-8.545-43.03-5.696-11.596-14.241-20.345-25.634-26.245-11.394-5.9-25.839-8.85-43.335-8.85-15.869 0-29.907 3.154-42.114 9.46C87.56 62.512 78.099 71.26 71.181 82.654c-6.714 11.393-10.07 24.821-10.07 40.283H4.653c0-22.583 5.696-43.132 17.09-61.646 11.393-18.514 27.364-33.264 47.912-44.25C90.408 6.054 114.415.56 141.678.56c26.855 0 50.354 4.782 70.495 14.344 20.142 9.358 35.808 23.397 46.997 42.114 11.19 18.514 16.785 41.606 16.785 69.275 0 11.19-2.645 23.193-7.934 36.011-5.087 12.614-13.123 24.414-24.109 35.4-10.783 10.986-24.821 20.04-42.115 27.161-17.293 6.917-38.045 10.376-62.255 10.376H91.324v-34.18zm0 46.387v-33.875h48.218c28.279 0 51.676 3.357 70.19 10.071 18.514 6.714 33.061 15.666 43.64 26.856 10.783 11.189 18.311 23.498 22.583 36.926 4.476 13.224 6.714 26.448 6.714 39.673 0 20.752-3.56 39.164-10.681 55.236-6.918 16.073-16.785 29.704-29.602 40.894-12.614 11.19-27.466 19.633-44.556 25.33-17.09 5.696-35.705 8.545-55.847 8.545-19.328 0-37.537-2.747-54.627-8.24-16.886-5.493-31.84-13.428-44.86-23.804-13.021-10.579-23.194-23.498-30.518-38.757C4.654 370.84.992 353.242.992 333.507h56.457c0 15.463 3.357 28.992 10.071 40.589 6.917 11.596 16.683 20.65 29.297 27.16 12.817 6.307 27.873 9.461 45.166 9.461 17.293 0 32.145-2.95 44.556-8.85 12.614-6.104 22.277-15.259 28.991-27.466 6.918-12.207 10.376-27.568 10.376-46.082s-3.865-33.671-11.596-45.471c-7.732-12.003-18.718-20.854-32.959-26.55-14.039-5.9-30.62-8.85-49.744-8.85H91.324z"
        opacity="0.49"
      />
      <defs>
        <linearGradient
          id="paint0_linear_599_27598"
          x1="-28"
          x2="338.667"
          y1="237.006"
          y2="237.006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
export default ApproachNum3

ApproachNum3.defaultProps = {
  width: 283,
  height: 458,
}
