//modules
import React from 'react'
import { useTranslation } from 'react-i18next'
//components
import OurTeamComp from './components/CompVersion'
import OurTeamMob from './components/MobVersion'
//interface
import { ScreenProps } from '../../interfaces/ScreenProps'
//styles
import './style.css'
import NavigationText from '../../components/navigationText'

const OurTeam = (props: ScreenProps) => {
  const { isMobileScreen, isLaptopWidth, isLandscape, language } = props
  const { t } = useTranslation()
  const teamArray: any[] = t('ourTeam', { returnObjects: true })

  return (
    <>
      {isMobileScreen && (
        <NavigationText
          animation={{ amount: 0.5 }}
          isLandscape={isLandscape}
          classLaptop={'navigationAboutLaptop'}
          classBigLaptop={'navigationAboutBLaptop'}
          classMobile={'navigationTeam'}
          textNavigation={t('buttons.TeamBtn')}
          isLaptopWidth={isLaptopWidth}
          isMobileScreen={isMobileScreen}
          classLandScape={'navigationAboutLand'}
        />
      )}
      {!isMobileScreen || isLandscape ? (
        <OurTeamComp teamArray={teamArray} />
      ) : (
        <OurTeamMob teamArray={teamArray} isLaptopWidth={isLaptopWidth} />
      )}
    </>
  )
}

export default OurTeam
