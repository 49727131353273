import React from 'react'
import { SvgProps } from '../../../interfaces/SvgProps'

function ApproachNum2(props: SvgProps) {
  const { width, height, onClick, className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 301 451"
      className={className}
      onClick={onClick}
    >
      <path
        fill="url(#paint0_linear_599_27480)"
        d="M300.064 404.613V451H9.231v-40.588L154.8 248.363c17.904-19.938 31.739-36.824 41.504-50.659 9.969-14.038 16.887-26.55 20.752-37.537 4.069-11.189 6.104-22.583 6.104-34.179 0-14.649-3.052-27.873-9.156-39.673-5.9-12.004-14.648-21.566-26.245-28.687-11.596-7.12-25.634-10.68-42.114-10.68-19.735 0-36.214 3.865-49.439 11.596C83.187 66.072 73.42 76.65 66.91 90.282c-6.51 13.631-9.766 29.297-9.766 46.997H.687c0-25.024 5.493-47.912 16.479-68.664 10.986-20.752 27.262-37.232 48.828-49.439C87.56 6.766 114.11.561 145.645.561c28.076 0 52.083 4.984 72.022 14.953 19.938 9.766 35.196 23.6 45.776 41.504 10.783 17.7 16.174 38.452 16.174 62.256 0 13.021-2.238 26.245-6.714 39.673-4.272 13.224-10.274 26.448-18.005 39.673-7.528 13.224-16.378 26.245-26.55 39.062-9.969 12.818-20.651 25.431-32.044 37.842L77.286 404.613h222.778z"
        opacity="0.49"
      />
      <defs>
        <linearGradient
          id="paint0_linear_599_27480"
          x1="-28"
          x2="338.667"
          y1="237.006"
          y2="237.006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"/>
          <stop offset="1" stopColor="#2033E0"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ApproachNum2

ApproachNum2.defaultProps = {
  width: 301,
  height: 451,
}
