//modules
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//interface
import { CaseScreenProps } from '../../../interfaces/CaseScreenProps'
//components
import ChildTitle from '../ChildCase/components/ChildTitle'

const Firewall = ({
  language,
  onClick,
  isMobileScreen,
  headerClick,
  isLaptopWidth,
}: CaseScreenProps) => {
  const { t } = useTranslation()

  const technoArray: string[] = t('firewall.technoArray', {
    returnObjects: true,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <ChildTitle
      isLaptopWidth={isLaptopWidth}
      headerClick={headerClick}
      processTitle={t('management.processTitle')}
      text={t('firewall.text')}
      title={t('firewall.title')}
      onClick={onClick}
      language={language}
      image={null}
      OutProjectArr={technoArray}
      isMobileScreen={isMobileScreen}
    />
  )
}

export default Firewall
