// modules
import React, { useRef, useState } from 'react'
//@ts-ignore
import * as Scroll from 'react-scroll'
import { useTranslation } from 'react-i18next'
// interface
import { ScreenProps } from '../../interfaces/ScreenProps'
// components
import Modal from '../../components/popUp'
import InputMobScreen from '../../components/inputMobScreen'
import NavigationText from '../../components/navigationText'
import ProjectLine from './components/ProjectLine'
import ProjectMobContent from './components/ProjectMobContent'
import ProjectSlider from './components/ProjectSlider'
// style
import './style.css'
import ArrowDown from '../../assets/svg/ArrowDown'
import { motion } from 'framer-motion'
import { ArrowAnim, HomeTextAnimation } from '../../utils/animation/data'

function Project(props: ScreenProps) {
  const {
    isMobileScreen,
    isLaptopWidth,
    isBigLaptop,
    language,
    isLandscape,
    setIndex,
    index,
  } = props
  const { t } = useTranslation()
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [inputScreen, setInputScreen] = useState<boolean>(false)
  let Link = Scroll.Link

  // let [returned] = useState(false)
  // const [activeIndex, setActiveIndex] = useState(0)
  // const ProjectArr = t('project.array', { returnObjects: true })
  // const startSlide = () => {
  //   // @ts-ignore
  //   customSlider.current.slickGoTo(4)
  //   setActiveIndex(4)
  // }
  //
  // const secondSlide = () => {
  //   // @ts-ignore
  //   customSlider.current.slickGoTo(3)
  //   setActiveIndex(3)
  // }
  // const thirdSlide = () => {
  //   // @ts-ignore
  //   customSlider.current.slickGoTo(2)
  //   setActiveIndex(2)
  // }
  // const fourthSlide = () => {
  //   // @ts-ignore
  //   customSlider.current.slickGoTo(1)
  //   setActiveIndex(1)
  // }
  // const lastSlide = () => {
  //   // @ts-ignore
  //   customSlider.current.slickGoTo(0)
  //   setActiveIndex(0)
  // }
  // const allLastSlide = () => {
  //   lastSlide()
  // }

  return (
    <div className={isMobileScreen ? 'projectScreenMob' : 'projectScreen'}>
      {isMobileScreen ? (
        <NavigationText
          animation={{ amount: 0.5, once: true }}
          isLandscape={isLandscape}
          isMobileScreen={isMobileScreen}
          isLaptopWidth={isLaptopWidth}
          isBigLaptop={isBigLaptop}
          classLaptop={'navigationProjectLap'}
          classBigLaptop={'navigationProjectBLap'}
          classMobile={'navigationProject'}
          textNavigation={t('buttons.getProjectBtn')}
          classLandScape={'navigationProjectLand'}
        />
      ) : null}
      {!isMobileScreen ? (
        <div className={'projectSlideContainer'}>
          <ProjectSlider
            language={language}
            aboutProjectTitle={t('project.title')}
            btnText={t('buttons.getProjectBtn')}
            setModalActive={setModalActive}
            aboutProjectText={t('project.text')}
          />

          <Modal active={modalActive} setActive={setModalActive} />
        </div>
      ) : isMobileScreen && !inputScreen ? (
        <ProjectMobContent
          isLandscape={isLandscape}
          isLaptopWidth={isLaptopWidth}
          isMobileScreen={isMobileScreen}
          isBigLaptop={isBigLaptop}
          setInputScreen={setInputScreen}
          btnText={t('buttons.getProjectBtn')}
          ProjectTitle={t('project.title')}
        />
      ) : isMobileScreen && inputScreen ? (
        <div
          className={
            isBigLaptop && !isLandscape
              ? 'projectInputs'
              : isLandscape
              ? 'projectInputsLand'
              : 'projectInputsMob'
          }
        >
          <InputMobScreen
            sucessMassageMob={'sucessMassageProj'}
            landscapeNav={'inputLandContainer'}
            isLandscape={isLandscape}
            isBigLaptop={isBigLaptop}
            isLaptopWidth={isLaptopWidth}
          />
        </div>
      ) : null}
      {!isMobileScreen && (
        <div className={'projectArrowsMain'}>
          <div className={'projectArrows'}>
            <Link
              to={'footer'}
              onClick={() => setIndex(7)}
              className={'projectLink'}
            >
              {index === 6 ? (
                <>
                  <ArrowDown className={'aboutArrow1'} />
                  <ArrowDown className={'aboutArrow2'} />
                  <ArrowDown className={'aboutArrow3'} />
                </>
              ) : null}
            </Link>
          </div>
        </div>
      )}

      <Modal active={modalActive} setActive={setModalActive} />
    </div>
  )
}

export default Project
