// modules
import React from 'react'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
// utils
import { svgAnimation, SvgApproachAnim } from '../../../../utils/animation/data'
// interface
import { approachSvgProps } from '../../../../interfaces/ApproachSvgPtops'
// assets
import {
  ApproachLine,
  ApproachLineShort,
  ApproachLineTall,
} from '../../../../assets/svg'
import MobSlideSvg from '../../../../assets/svg/MobSlideSvg'

const ApproachSvg = ({
  circle,
  setCircle,
  isLittleHeight,
  isBigHeight,
  isLaptopWidth,
  isMobileScreen,
  isLandscape,
}: approachSvgProps) => {
  return (
    <motion.div
      initial={'hidden'}
      whileInView={'visible'}
      viewport={{ amount: 0.6 }}
    >
      {isLittleHeight && !isMobileScreen ? (
        <motion.div custom={2} variants={SvgApproachAnim}>
          <ApproachLineShort
            radius1={circle == 'circle1' ? 50 : 25}
            radius2={circle == 'circle2' ? 50 : 25}
            radius3={circle == 'circle3' ? 50 : 25}
            radius4={circle == 'circle4' ? 50 : 25}
            className={'ApproachLine'}
            onClick1={() => {
              setCircle(['circle1'])
            }}
            onClick2={() => {
              setCircle(['circle2'])
            }}
            onClick3={() => {
              setCircle(['circle3'])
            }}
            onClick4={() => {
              setCircle(['circle4'])
            }}
          />
        </motion.div>
      ) : isBigHeight && !isMobileScreen ? (
        <motion.div custom={2} variants={SvgApproachAnim}>
          <ApproachLineTall
            radius1={circle == 'circle1' ? 50 : 25}
            radius2={circle == 'circle2' ? 50 : 25}
            radius3={circle == 'circle3' ? 50 : 25}
            radius4={circle == 'circle4' ? 50 : 25}
            className={'ApproachLine'}
            onClick1={() => {
              setCircle(['circle1'])
            }}
            onClick2={() => {
              setCircle(['circle2'])
            }}
            onClick3={() => {
              setCircle(['circle3'])
            }}
            onClick4={() => {
              setCircle(['circle4'])
            }}
          />
        </motion.div>
      ) : isMobileScreen ? (
        <motion.div
          initial={'hidden'}
          whileInView={'visible'}
          viewport={{ amount: 0.5 }}
        >
          <motion.div
            className={!isLaptopWidth ? 'mobSvg' : 'laptopSvg'}
            custom={2}
            variants={svgAnimation}
          >
            <MobSlideSvg
              className={!isLandscape ? 'mobSvg' : 'landSvg'}
              radius1={circle == 'circle1' ? 22 : 11}
              radius2={circle == 'circle2' ? 22 : 11}
              radius3={circle == 'circle3' ? 22 : 11}
              radius4={circle == 'circle4' ? 22 : 11}
              onClick1={() => {
                setCircle(['circle1'])
              }}
              onClick2={() => {
                setCircle(['circle2'])
              }}
              onClick3={() => {
                setCircle(['circle3'])
              }}
              onClick4={() => {
                setCircle(['circle4'])
              }}
            />
          </motion.div>
        </motion.div>
      ) : (
        <motion.div custom={2} variants={SvgApproachAnim}>
          <ApproachLine
            radius1={circle == 'circle1' ? 50 : 25}
            radius2={circle == 'circle2' ? 50 : 25}
            radius3={circle == 'circle3' ? 50 : 25}
            radius4={circle == 'circle4' ? 50 : 25}
            className={'ApproachLine'}
            onClick1={() => {
              setCircle(['circle1'])
            }}
            onClick2={() => {
              setCircle(['circle2'])
            }}
            onClick3={() => {
              setCircle(['circle3'])
            }}
            onClick4={() => {
              setCircle(['circle4'])
            }}
          />
        </motion.div>
      )}
    </motion.div>
  )
}

export default ApproachSvg
