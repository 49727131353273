//modules
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
//constants
import { AvatarArr } from '../../../../constants'

const OurTeamMob = ({ teamArray, isLaptopWidth }: any) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const customSlider = useRef<any>()
  const sliderAvatar = useRef<any>()

  const currentSlide = (slide: number) => {
    setActiveSlide(slide)
    customSlider.current.slickGoTo(slide)
    sliderAvatar.current.slickGoTo(slide)
  }
  return (
    <div className={'MainTeamMob'}>
      <Slider
        className={'TeamSlider'}
        arrows={false}
        ref={customSlider}
        swipe={false}
      >
        {teamArray.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={!isLaptopWidth ? 'memberCardMob' : 'memberCardLap'}
            >
              <div>
                <div>
                  <img
                    src={item.img}
                    alt="member"
                    className={isLaptopWidth ? 'memberPhotoLap' : 'memberPhoto'}
                  />
                </div>
                <p className={'titleTeamMob'}>{item.titleTeam}</p>
                <p className={'memberNameMob'}>{item.nameMember}</p>
              </div>
            </div>
          )
        })}
      </Slider>
      <div>
        <Slider
          className={'avatarSlider'}
          arrows={false}
          slidesToShow={2.5}
          ref={sliderAvatar}
          swipe={false}
        >
          {AvatarArr.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  activeSlide === item.slide && !isLaptopWidth
                    ? 'avatarSlideActive'
                    : activeSlide === item.slide && isLaptopWidth
                    ? 'avatarSlideActiveLap'
                    : isLaptopWidth
                    ? 'avatarSlideContainerLap'
                    : 'avatarSlideContainer'
                }
              >
                <img
                  src={item.img}
                  alt="slider"
                  className={
                    !isLaptopWidth ? 'avatarSlideImg' : 'avatarSlideImgLap'
                  }
                  onClick={() => currentSlide(item.slide)}
                />
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default OurTeamMob
