//modules
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//components
import ChildCase from '../ChildCase'
//assets
import { images } from '../../../assets/image'
//styles
import '../Fitness/style.css'
//interface
import { CaseScreenProps } from '../../../interfaces/CaseScreenProps'

const Management = ({
  onClick,
  language,
  isMobileScreen,
  headerClick,
  isLaptopWidth,
}: CaseScreenProps) => {
  const { t } = useTranslation()

  const functionArr: any[] = t('management.functionArray', {
    returnObjects: true,
  })
  const taskArray: any[] = t('management.taskArray', {
    returnObjects: true,
  })
  const processArray: any[] = t('management.processArray', {
    returnObjects: true,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ChildCase
      isLaptopWidth={isLaptopWidth}
      isMobileScreen={isMobileScreen}
      title={t('management.title')}
      text={t('management.text')}
      functionArr={functionArr}
      taskArr={taskArray}
      OutProjectArr={processArray}
      image={isMobileScreen ? null : images.managementImg}
      onClick={onClick}
      language={language}
      functionTitle={t('management.functionTitle')}
      processTitle={t('management.processTitle')}
      taskTitle={t('management.taskTitle')}
      headerClick={headerClick}
    />
  )
}

export default Management
