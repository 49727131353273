//modules
import React, { memo } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
//components
import NavigationText from '../../components/navigationText'
import AboutText from './components/AboutText'
import AboutImg from './components/AboutImg'
// utils
import '../../utils/i18next'
// interface
import { ScreenProps } from '../../interfaces/ScreenProps'
//styles
import './style.css'
import { AboutUs } from './style'
import { Experience } from '../index'

function About(props: ScreenProps) {
  const {
    isMobileScreen,
    isBigLaptop,
    isLaptopWidth,
    language,
    isLandscape,
    setScreen,
    activeExp,
    setActiveExp,
    customSwapExp,
  } = props
  const { t } = useTranslation()

  return (
    <AboutUs>
      {isMobileScreen ? (
        <NavigationText
          animation={{ amount: 0.5 }}
          isLandscape={isLandscape}
          classLaptop={'navigationAboutLaptop'}
          classBigLaptop={'navigationAboutBLaptop'}
          classMobile={'navigationAbout'}
          textNavigation={t('buttons.expBtn')}
          isBigLaptop={isBigLaptop}
          isLaptopWidth={isLaptopWidth}
          isMobileScreen={isMobileScreen}
          classLandScape={'navigationAboutLand'}
        />
      ) : null}
      {!activeExp ? (
        <motion.div
          initial={'hidden'}
          whileInView={'visible'}
          viewport={{ amount: 0.2 }}
          className={'mainAbout'}
        >
          <AboutText
            setActive={setActiveExp}
            isLandscape={isLandscape}
            language={language}
            isMobileScreen={isMobileScreen}
            isBigLaptop={isBigLaptop}
            isLaptopWidth={isLaptopWidth}
          />
          {!isMobileScreen && (
            <AboutImg
              isLandscape={isLandscape}
              isMobileScreen={isMobileScreen}
              isBigLaptop={isBigLaptop}
            />
          )}
        </motion.div>
      ) : (
        <motion.div
          initial={'hidden'}
          whileInView={'visible'}
          className={'aboutSlider'}
          viewport={{ amount: 0.2, once: true }}
        >
          <Experience
            customSwapExp={customSwapExp}
            setScreen={setScreen}
            isLandscape={isLandscape}
            isMobileScreen={isMobileScreen}
            isLaptopWidth={isLaptopWidth}
          />
        </motion.div>
      )}
    </AboutUs>
  )
}

export default memo(About)
