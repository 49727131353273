// modules
import axios from 'axios'
// constant
import { CHAT_ID, URL_API, URL_API_DOCUMENT } from '../../api/telegram'
import { text } from 'stream/consumers'

export const HandleChange = (e: any, setInputValue: any, inputValue: any) => {
  e.preventDefault()
  setInputValue({
    ...inputValue,
    [e.target.name]: e.target.value,
  })
}

export const CheckFull = (
  Email: string,
  Name: string,
  AboutProject: string,
  setErrEmail: (b: boolean) => void,
  setErrName: (b: boolean) => void,
  setErrValue: (b: boolean) => void,
  country: string,
  city: string,
  ip: string,
  setShow: any,
  file: any
) => {
  if (Email.length <= 1 || Name.length <= 1 || AboutProject.length <= 1) {
    setErrEmail(true)
    setErrName(true)
    setErrValue(true)
    if (Email.length <= 1) {
      setErrEmail(true)
    } else if (Name.length <= 1) {
      setErrName(true)
    } else if (AboutProject.length <= 1) {
      setErrValue(true)
    }
  } else {
    let message = `<b>New Application!</b>\n`
    message += `<b>Name: </b> ${Name}\n`
    message += `<b>Email: </b> ${Email}\n`
    message += `<b>About Project: </b> ${AboutProject}\n`
    message += `<b>Country: </b> ${country}\n`
    message += `<b>City: </b> ${city}\n`
    message += `<b>User IP: </b> ${ip}\n`
    const formData: any = new FormData()

    formData.append('chat_id', CHAT_ID)
    formData.append('document', file)


    Promise.all([
      axios
        .post(URL_API, {
          chat_id: CHAT_ID,
          text: message,
          parse_mode: 'html',
        })
        .then(() => {
          Email = ''
          Name = ''
          AboutProject = ''
        })
        .catch((err) => {
          console.warn(err)
        }),
      axios
        .post(URL_API_DOCUMENT, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch((error) => {
          console.warn(error)
        }),
    ])

    setShow(true)
  }
}
