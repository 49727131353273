//modules
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//components
import ChildTitle from '../ChildCase/components/ChildTitle'
//interface
import { CaseScreenProps } from '../../../interfaces/CaseScreenProps'
import { images } from '../../../assets/image'

const Health = ({
  language,
  onClick,
  isMobileScreen,
  headerClick,
  isLaptopWidth,
}: CaseScreenProps) => {
  const { t } = useTranslation()

  const technoArray: string[] = t('health.technoArray', {
    returnObjects: true,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ChildTitle
      isLaptopWidth={isLaptopWidth}
      headerClick={headerClick}
      text={t('health.text')}
      title={t('health.title')}
      onClick={onClick}
      language={language}
      image={isMobileScreen ? null : images.healthImg}
      OutProjectArr={technoArray}
      processTitle={t('management.processTitle')}
      isMobileScreen={isMobileScreen}
    />
  )
}

export default Health
