import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

const MobSlideSvg = (props: SvgProps) => {
  const {
    width,
    height,
    className,
    radius1,
    radius2,
    radius3,
    radius4,
    onClick1,
    onClick2,
    onClick3,
    onClick4,
  } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 271 44"
    >
      <path stroke="#fff" strokeLinecap="round" d="M2 22h264" />
      <circle
        cx="22"
        cy="22"
        r={radius1}
        onClick={onClick1}
        className={radius1 === 11 ? 'circleAnim' : 'circle'}
        fill="url(#paint0_linear_1675_7191)"
      />
      <circle
        cx="94"
        cy="22"
        r={radius2}
        onClick={onClick2}
        className={radius2 === 11 ? 'circleAnim' : 'circle'}
        fill="url(#paint1_linear_1675_7191)"
      />
      <circle
        cx="177"
        cy="22"
        r={radius3}
        onClick={onClick3}
        className={radius3 === 11 ? 'circleAnim' : 'circle'}
        fill="url(#paint2_linear_1675_7191)"
      />
      <circle
        cx="260"
        cy="22"
        r={radius4}
        className={radius4 === 11 ? 'circleAnim' : 'circle'}
        onClick={onClick4}
        fill="url(#paint3_linear_1675_7191)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1675_7191"
          x1="0"
          x2="45.833"
          y1="22"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1675_7191"
          x1="83"
          x2="105.917"
          y1="22"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1675_7191"
          x1="166"
          x2="188.917"
          y1="22"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1675_7191"
          x1="249"
          x2="271.917"
          y1="22"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default MobSlideSvg

MobSlideSvg.defaultProps = {
  radius: 100,
  width: 1000,
  height: 150,
}
