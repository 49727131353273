import i18n from 'i18next'
import BackEnd from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import {
  Hand,
  Trust,
  Open,
  Work,
  Man,
  Member1,
  Member2,
  Member3,
  Member4,
  Member5,
  FitnessSvg,
  Management,
  OnlineStore,
  Bike,
  CirageStore,
  AutoPart,
  Health,
  FinSystem,
  ILXS,
  Eldax,
  Firewall,
} from '../assets/svg'

// @ts-ignore
i18n
  .use(BackEnd)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'ru',
    fallbackLng: 'en',
    whitelist: ['en', 'ru'],
    resources: {
      en: {
        translation: {
          buttons: {
            homeBtn: 'Home',
            expBtn: 'Experience',
            approachBtn: 'Approach',
            serviceBtn: 'Our service',
            TeamBtn: 'Our team',
            ProjectBtn: 'Get a project',
            technologyBtn: 'Technologies',
            getProjectBtn: 'Get a project',
          },
          linkArr: [
            { id: 0, name: 'Home', path: 'home' },
            { id: 1, name: 'Experience', path: 'experience' },
            { id: 2, name: 'Approach', path: 'approach' },
            { id: 3, name: 'Service', path: 'service' },
            { id: 4, name: 'Our Team', path: 'ourTeam' },
            { id: 5, name: 'Technologies', path: 'technology' },
            { id: 6, name: 'Get a project', path: 'getProject' },
          ],
          inputs: {
            text: 'Please leave your contacts so we can contact you.',
            name: 'Name',
            textarea: 'Describe your project',
          },
          home: {
            title:
              'We help businesses test digital product ideas and successfully bring them to the market. ',
            text: 'Making your project one of the best with the help of IT is one of the main goals of ForDream.',
            achievement1: 'more than 20 mobile apps',
          },
          about: {
            text: 'We have seen successes and failures, so before we start working on a project, we analyze the structure of the future product step by step, identifying potential difficulties that startups face, thereby preventing negative situations that they can cause.',
          },
          main: [
            {
              title: 'Experience',
              text: `We have a lot of experience in creating different products. We have an understanding of how different industries work.`,
              img: Hand,
            },
            {
              title: 'Trust',
              text: 'We are a reliable and proven company, what has successfully implemented the ideas of all our customers.',
              img: Trust,
            },
            {
              title: 'Open to you',
              text: 'We all have flaws, but we recognize our mistakes, and try to solve any problem as quickly as possible.',
              img: Open,
            },
            {
              title: 'Hard work',
              text: 'We take pride in every project we work on. Our company always does its job properly and move forward.',
              img: Work,
            },
            {
              title: 'People',
              text: 'Only the most talented employees work in our team. We carefully select our staff. Our company is our second family!',
              img: Man,
            },
          ],
          approach: {
            slide1:
              'Before proceeding with the execution of the order, we carefully study your wishes, analyze the "sales" channels and detail the project.',
            slide2:
              'We minimize bureaucratic processes. For us, the relationship between people comes first.',
            slide3:
              'We don\'t just create, we "personally invest" in clients\' businesses - supporting their additional needs.',
            slide4:
              'We also offer independent expertise in business development, UI/UX design and mobile/web engineering.',
          },
          project: {
            title: 'Do you want to work with us?',
            text: 'We are the ones who realize your dreams and ideas, making this world progressive. ',
            array: [
              { text: 'We study your goals and needs' },
              { text: 'We conduct interviews with our specialists' },
              { text: 'Drawing up a roadmap' },
              {
                text: 'Stable releases for your tests and familiarization with the results',
              },
              { text: 'We provide the finished product and support it' },
            ],
          },
          service: {
            UI: [
              { text: 'Website design' },
              { text: 'Application design' },
              { text: 'Product design' },
              { text: 'Logo development' },
              { text: 'Redesign' },
              { text: 'Illustrations' },
            ],
            web: [
              { text: 'Site card' },
              { text: 'Corporate website' },
              { text: 'Online store' },
              { text: 'One-page site (landing page)' },
              { text: 'Marketplace' },
            ],
            mobile: [
              { text: 'Applications for learning' },
              { text: 'Applications for financial organizations' },
              { text: 'Social Applications' },
              { text: 'Business Applications' },
              { text: 'MVP' },
            ],
            layout: [
              {
                text: 'If you have a ready-made design developed by other specialists, our programmers are ready to implement it.',
              },
            ],
          },
          ourTeam: [
            {
              titleTeam: 'СIО',
              nameMember: 'Alexandr Kameko',
              img: Member1,
            },
            {
              titleTeam: 'СOО',
              nameMember: 'Valeria Popova',
              img: Member2,
            },
            {
              titleTeam: 'PM',
              nameMember: 'Miroslav Peshkov',
              img: Member2,
            },
            {
              titleTeam: 'UI/UX',
              nameMember: 'Elizaveta Torskaya',
              img: Member3,
            },
            {
              titleTeam: 'Frontend',
              nameMember: 'Oleg Klokov',
              img: Member4,
            },
            {
              titleTeam: 'Backend',
              nameMember: 'Vasily Stankevich',
              img: Member5,
            },
          ],
          experience: [
            {
              title: 'UI/UX design',
              text:
                'Our company supports the SaaS business at every stage: \n' +
                '- research \n' +
                '- UI / UX prototyping \n' +
                '- software development \n' +
                '- real-time application maintenance”',
              img: [
                { image: FitnessSvg, id: 1 },
                { image: Management, id: 2 },
                { image: OnlineStore, id: 3 },
              ],
            },
            {
              title: 'Frontend development',
              text: 'Despite the fact that our company is quite young, we have already successfully completed more than 12 mobile applications. Our team bravely meets all difficulties and overcomes them. We will be glad to see your project and implement it.',
              img: [
                { image: Bike, id: 4 },
                { image: CirageStore, id: 5 },
                { image: AutoPart, id: 6 },
              ],
            },
            {
              title: 'Middleware development',
              text: 'Web development is a painstaking work that must be done according to all the rules in order for the end result to be successfully implemented. ForDream is a company that will help you turn all your ideas and novelties into reality.',
              img: [
                { image: Health, id: 7 },
                { image: FinSystem, id: 8 },
                { image: ILXS, id: 9 },
                { image: Eldax, id: 10 },
                { image: Firewall, id: 11 },
              ],
            },
          ],
          technologies: {
            web: [
              { text: 'Redux' },
              { text: 'MobX' },
              { text: 'TypeScript/PropTypes' },
              { text: 'Firebase/Rest API' },
              { text: 'Graphql' },
              { text: 'React Bootstrap' },
              { text: 'Material UI / Sass Stylesheet Styled components' },
            ],
            mobile: [
              { text: 'Redux' },
              { text: 'MobX' },
              { text: 'TypeScript/PropTypes' },
              { text: 'NativeBase' },
              { text: 'Expo' },
              { text: 'Firebase/Rest API' },
              { text: 'Styled Components' },
              { text: 'React Native Material UI' },
            ],
            UI: [
              { text: 'Site design' },
              { text: 'Application design' },
              { text: 'Product design' },
              { text: 'Logo development' },
              { text: 'Redesign' },
              { text: 'Illustrations' },
              { text: 'Fintech' },
            ],
            backend: [
              { text: 'JQuery' },
              { text: 'LINQ' },
              { text: 'XML' },
              { text: 'Telerik Kendo UI' },
              { text: 'SignalR' },
              { text: 'Google RPC' },
              { text: 'MS-SQL' },
              { text: 'PostgreSQL' },
            ],
          },
          popUp: {
            text: 'Thank you! Our manager will connect you soon!',
            btnConfirm: 'Confirm',
          },
          footer: {
            AboutTitle: 'About us',
            ServiceTitle: 'Service',
            ConnectionTitle: 'Connection',
            ContactTitle: 'Contact',
            ContactText: 'phone',
          },
          fitness: {
            title: 'Redesign fitness application',
            text: 'The idea of the project is to offer users an individual set of exercises based on their preferences, desires, capabilities and goals',
            functionTitle: 'functions',
            functionArray: [
              { text: 'create an account' },
              {
                text: 'filling out a questionnaire for the selection of the complex',
              },
              {
                text: 'a wide range of different exercises and complexes for every taste',
              },
              { text: 'paid sets of exercises' },
            ],
            problemTitle: 'Problems',
            problemArray: [
              { text: 'inconvenient functionality' },
              { text: 'unpleasant visual' },
              { text: 'lack of needed features' },
            ],
            processTitle: 'Our process:',
            processArr: [
              { text: 'briefing the client' },
              { text: 'conducting ux research, identifying issues' },
              { text: 'adding features' },
              { text: 'interviewing potential users' },
              { text: 'describing user-flow' },
              { text: 'prototyping' },
              { text: 'creating a design system / creating ui kits' },
              { text: 'creating mockups' },
            ],
            OutputTitle: 'Output project',
            outputArr: [
              { text: 'account creation' },
              {
                text: 'filling out a questionnaire for the selection of the complex',
              },
              {
                text: 'a wide range of different exercises and complexes for every taste',
              },
              { text: 'paid sets of exercises' },
              { text: 'viewing your results' },
              {
                text: 'completing the questionnaire every week to see the changes',
              },
              { text: 'ability to interact with other users' },
              { text: 'opportunity to work directly with a trainer online' },
              { text: 'getting bonuses for completed marathons' },
            ],
          },
          management: {
            title: 'Management WEB application',
            text: 'The idea of the project is to allow users to enter their data about their projects and tasks, and view their results, improving their productivity.',
            functionTitle: 'Functions:',
            functionArray: [
              { text: 'creating multiple projects' },
              { text: 'creating tasks within projects' },
              { text: 'timer' },
              { text: 'activity graphs' },
              {
                text: 'receive notifications when a timer is missed or a task not completed on time',
              },
              { text: 'show results every week/month/year' },
            ],
            taskTitle: 'Tasks:',
            taskArray: [
              { text: 'design from scratch' },
              { text: 'web design version' },
              { text: 'user research' },
            ],
            processTitle: 'Our process',
            processArray: [
              { text: 'conducting a briefing with the client' },
              { text: 'conducting ux research' },
              { text: 'setting priorities' },
              { text: 'setting the main goals of the project' },
              { text: 'interviewing potential users' },
              { text: 'describing user-flow' },
              { text: 'prototyping' },
              { text: 'creating a design system / creating ui kits' },
              { text: 'creating mockups' },
            ],
          },
          onlineStore: {
            title: 'Cosmetics online store / Responsive design',
            text: 'The idea of the project is to create a design for a web and mobile version of an online store selling natural cosmetics.',
            functionArray: [
              { text: 'the ability to view products' },
              { text: 'ability to find products using search and filters' },
              { text: 'possibility to order goods and pay through the site' },
              {
                text: 'possibility of feedback via online chat within the site',
              },
              { text: 'create an account' },
              { text: 'getting discounts' },
              { text: 'making a return or exchange through the site' },
            ],
            taskArray: [
              { text: 'design from scratch' },
              { text: 'web design version' },
              { text: 'mobile design version' },
              { text: 'user research' },
            ],
            processArray: [
              { text: 'conducting a briefing with the client' },
              { text: 'conducting ux research' },
              { text: 'setting priorities' },
              { text: 'setting the main goals of the project' },
              { text: 'interviewing potential users' },
              { text: 'describing user-flow' },
              { text: 'prototyping' },
              { text: 'creating a design system / creating ui kits' },
              { text: 'creating mockups' },
            ],
          },
          electricBike: {
            title: 'Electric bike online store',
            text: 'The idea of the project is that the user is allowed to independently assemble his own bicycle using a constructor.',
            reqTitle: 'Requirements:',
            reqArray: [
              { text: 'create a user site and an admin site.' },
              { text: 'create a parts list' },
              { text: 'the ability to purchase and pay online' },
            ],
            impTitle: 'Implementation:',
            impArray: [
              { text: 'implement scroll with component container' },
              { text: 'spare parts sorting' },
              { text: 'avoid conflict between components' },
              { text: 'initial sorting by size/battery capacity/size' },
            ],
            technoTitle: 'Technologies',
            technoArray: [
              { text: 'React JS' },
              { text: 'Redux' },
              { text: 'PropTypes' },
              { text: 'CSS/SCSS' },
              { text: 'ESLint' },
              { text: 'Rest API' },
              { text: 'Airbnb' },
              { text: 'Parcel' },
            ],
          },
          cigar: {
            title: 'Cigar online shop',
            text: 'The idea of the site is to sell cigars of expensive brands from different countries.',
            reqTitle: 'Requirements:',
            reqArray: [
              { text: 'choose cigarettes for every taste using filters' },
              { text: 'choice of country of origin' },
              { text: 'choice of tobacco/size' },
              { text: 'choosing the right alcohol' },
            ],
            impTitle: 'Implementation:',
            impArray: [
              { text: 'creation of filters by specified parameters' },
              { text: 'registration and payment online' },
            ],
            technoTitle: 'Technologies',
            technoArray: [
              { text: 'React JS' },
              { text: 'Redux Toolkit' },
              { text: 'TypeScript' },
              { text: 'StyledComponents' },
              { text: 'ESLint' },
              { text: 'Rest API' },
              { text: 'Airbnb' },
              { text: 'Docker' },
              { text: 'Material UI' },
              { text: 'Sentry' },
            ],
          },
          autoPart: {
            title: 'Auto parts store for car dealership/Mobile app',
            text: 'The idea of the project is to allow the user to choose parts for the car, as well as a mechanic who will work with his auto.',
            reqTitle: 'Requirements:',
            reqArray: [
              { text: 'filter by the car brand' },
              { text: 'selection of spare parts' },
              { text: 'selection of mechanics' },
              { text: 'general base of spare parts' },
              { text: 'payment and registration online' },
            ],
            impTitle: 'Implementation:',
            impArray: [
              {
                text: 'creation of a common database of spare parts, where user data and information about the replacement of his spare parts will be stored',
              },
              { text: 'scanning the number and indicating the registration' },
              {
                text: 'a database that stores all data about a registered auto',
              },
            ],
            technoTitle: 'Technologies',
            technoArray: [
              { text: 'React Native' },
              { text: 'RTK Query' },
              { text: 'TypeScript' },
              { text: 'ESLint' },
              { text: 'Push Notification' },
              { text: 'Localization' },
              { text: 'Google Maps' },
              { text: 'Camera' },
              { text: 'QR Scanner' },
              { text: 'Sentry' },
            ],
          },
          health: {
            title: 'Dfx.Api.Health-Checks',
            text: 'Subsystem for checking the life support of services of the auto maintenance system in the USA/Canada based on the NServiceBus bulk message processing library.',
            technoArray: [
              { text: 'Code structure analysis' },
              { text: 'Deep refactoring' },
              { text: 'Implementing BDD Unit Testing' },
              { text: 'Allocation of pure SDK' },
              { text: 'Creating a normal prototype using the developed SDK' },
              { text: 'Generation of technical documentation' },
            ],
          },
          finSystem: {
            title: 'FinSys System',
            text: 'Financial system for ordinary users.',
            technoArray: [
              {
                text: 'development of architecture, modular framework based on ASP .NET4',
              },
              { text: 'developing a data access layer based on EF6.0' },
              {
                text: 'development of an initial database deployment program based on EF6.0',
              },
              { text: 'development of the central modules of the framework' },
              {
                text: 'development of an authorization and authentication mechanism',
              },
              {
                text: 'development and support of the multilingual system module',
              },
              {
                text: 'development of a Windows service for obtaining exchange rates from the site www.xe.xom',
              },
              {
                text: 'developing the Windows Financial Transaction Services Service',
              },
              {
                text: 'development and support of the SMS distribution module',
              },
              { text: 'development and support of the WebApi module' },
              {
                text: 'development and support of a client library for accessing the system core from an external project that implements the user interface of the system',
              },
              {
                text: 'development and support of tests for the client library for accessing the system core from an external project that implements the user interface of the system',
              },
            ],
          },
          ILXSys: {
            title: 'ILX System',
            text: 'Financial system for currency dealers.',
            technoArray: [
              { text: 'website architecture development' },
              { text: 'developing a data access layer based on EF6.0' },
              { text: 'development of the WebAPI subsystem' },
              { text: 'website interface development' },
              {
                text: 'development and support of a client library for accessing the system core from an external project that implements the user interface of the system',
              },
              {
                text: 'development and support of tests for the client library for accessing the system core from an external project that implements the user interface of the system',
              },
            ],
          },
          archiveSys: {
            title: 'Eldax',
            text: 'Electronic archive system.',
            technoArray: [
              {
                text: 'Creation and maintenance of the overall project architecture',
              },
              {
                text: 'creation and support of a common project multilingual system',
              },
              {
                text: 'create and maintain local system nuget project packages',
              },
              {
                text: 'creation and support of the project authorization and authentication module',
              },
              { text: 'creation and support of the project logging module' },
              {
                text: 'creation and support of the "Storages" module of the system',
              },
              {
                text: 'creation and support of the “Universal lookups” module',
              },
              {
                text: 'creation and maintenance of the “Permissions” section',
              },
            ],
          },
          firewall: {
            title: 'The Great Firewall of China',
            text: 'Bypass system of the Golden Shield',
            technoArray: [
              {
                text: 'writing a Helper Business Object in C#, for IE 8-11, a client part that, interacting with the server part, sends statistics and receives redirect rules for the specified blocked domains',
              },
              {
                text: 'server part, ASP .NET MVC, writing a statistics subsystem, processing 5,000,000 records in the MS SQL Server database',
              },
              {
                text: 'making changes, new functionality, to the plug-in for Google Chrome analogue of BHO for IE 8-11',
              },
              {
                text: 'collection of static information from pages visited by the Google Chrome user that falls under certain rules and sending these statistics to the server',
              },
              {
                text: 'processing static information received from the plugin for Google Chrome on the server',
              },
              { text: 'deployment of MS SQL Server Reporting System' },
              {
                text: 'synchronization of databases, production & reporting to get up-to-date data for reporting',
              },
              {
                text: 'server side, ASP .NET MVC, writing an authorization and authentication subsystem',
              },
              {
                text: 'server part, ASP .NET MVC, writing a subsystem for determining the country, city, region based on MaxMind DB GeoIp, issuing private rules for a specific country for the client part of the system',
              },
            ],
          },
          NDA: 'According to the NDA we can’t provide you more information',
        },
      },
      ru: {
        translation: {
          buttons: {
            homeBtn: 'Главная',
            expBtn: 'Опыт',
            approachBtn: 'Подход',
            serviceBtn: 'Наши услуги',
            TeamBtn: 'Команда',
            ProjectBtn: 'Контакты',
            technologyBtn: 'Технологии',
            getProjectBtn: 'Получить проект',
          },
          linkArr: [
            { id: 0, name: 'Главная', path: 'home' },
            { id: 1, name: 'Опыт', path: 'experience' },
            { id: 2, name: 'Подход', path: 'approach' },
            { id: 3, name: 'Наши услуги', path: 'service' },
            { id: 4, name: 'Команда', path: 'ourTeam' },
            { id: 5, name: 'Технологии', path: 'technology' },
            { id: 6, name: 'Проект', path: 'getProject' },
          ],
          inputs: {
            text: 'Оставьте свои контакты для связи.',
            name: 'Имя',
            textarea: 'Опишите свой проект',
          },
          home: {
            title:
              'Помогаем проекту тестировать идеи цифровых продуктов и успешно выводить их на рынок.',
            text: 'Сделать ваш проект одним из лучших с помощью IТ — одна из основных целей ForDream.',
          },
          about: {
            text: 'Мы видели успехи и неудачи, поэтому, прежде чем приступить к работе над проектом, мы поэтапно анализируем структуру будущего продукта, выявляя потенциальные трудности, с которыми сталкиваются стартапы, тем самым предотвращая негативные ситуации, которые они могут вызвать.',
          },
          main: [
            {
              title: 'Опыт',
              text: `У нас есть большой опыт в создании различных продуктов, а также понимание того, как работают разные отрасли.`,
              img: Hand,
            },
            {
              title: 'Доверие',
              text: 'Мы - надежная и проверенная компания, которая успешно реализовала идеи всех своих клиентов.',
              img: Trust,
            },
            {
              title: 'Открыты критике',
              text: 'У всех нас есть недостатки, мы признаем свои ошибки и стараемся решить любую проблему как можно качественнее.',
              img: Open,
            },
            {
              title: 'Усердие',
              text: 'Мы гордимся каждым реализованным проектом. Усердие, профессионализм и трудолюбие всегда с нами.',
              img: Work,
            },
            {
              title: 'Люди',
              text: 'В нашей команде работают только самые талантливые сотрудники. Наша компания – это вторая семья!',
              img: Man,
            },
          ],
          approach: {
            slide1:
              'Прежде чем приступить к выполнению заказа, мы внимательно изучаем ваши пожелания, анализируем каналы «продажи» и детализируем проект.',
            slide2:
              'Мы минимизируем бюрократические процессы. Для нас отношения между людьми на первом месте.',
            slide3:
              'Мы не просто создаем, мы "лично инвестируем" в бизнес клиентов, поддерживая их дополнительные потребности.',
            slide4:
              'Мы также предлагаем независимую экспертизу в области развития бизнеса, дизайна пользовательского интерфейса / UX и мобильной / веб-инженерии.',
          },
          project: {
            title: 'Хочешь работать с нами?',
            text: 'Мы те, кто реализует ваши мечты и идеи, делая этот мир прогрессивным.',
            array: [
              { text: 'Мы изучаем ваши цели и потребности' },
              { text: 'Мы проводим собеседования с нашими специалистами' },
              { text: 'Занимаемся составлением дорожной карты' },
              {
                text: 'Гарантируем стабильные релизы для ваших тестов и ознакомление с результатами',
              },
              { text: 'Мы предоставляем готовый продукт и поддерживаем его' },
            ],
          },
          service: {
            UI: [
              { text: 'Веб дизайн' },
              { text: 'Мобильный дизайн' },
              { text: 'Дизайн продукта' },
              { text: 'Разработка логотипа' },
              { text: 'Редизайн' },
              { text: 'Иллюстрации' },
            ],
            web: [
              { text: 'Сайт визитка' },
              { text: 'Корпоративный сайт' },
              { text: 'Онлайн магазин' },
              { text: 'Лендинг' },
              { text: 'Торговая площадка' },
            ],
            mobile: [
              { text: 'Приложения для обучения' },
              { text: 'Приложения для финансовых организаций' },
              { text: 'Социальные Приложения' },
              { text: 'Бизнес Приложения' },
              { text: 'MVP' },
            ],
            layout: [
              {
                text: 'Если есть готовый дизайн, разработанный другими специалистами, наши программисты готовы его реализовать.',
              },
            ],
          },
          ourTeam: [
            {
              titleTeam: 'СIО',
              nameMember: 'Alexandr Kameko',
              img: Member1,
            },
            {
              titleTeam: 'СOО',
              nameMember: 'Valeria Popova',
              img: Member2,
            },
            {
              titleTeam: 'PM',
              nameMember: 'Miroslav Peshkov',
              img: Member2,
            },
            {
              titleTeam: 'UI/UX',
              nameMember: 'Elizaveta Torskaya',
              img: Member3,
            },
            {
              titleTeam: 'Frontend',
              nameMember: 'Oleg Klokov',
              img: Member4,
            },
            {
              titleTeam: 'Backend',
              nameMember: 'Vasily Stankevich',
              img: Member5,
            },
          ],
          experience: [
            {
              title: 'UI/UX дизайн',
              text:
                'Наша компания предоставляет UI/UX дизайн для вашего бизнеса:\n' +
                '- исследование \n' +
                '- UI/UX прототипирование \n' +
                '- редизайн \n' +
                '- UI/UX дизайн с нуля',
              img: [
                { image: FitnessSvg, id: 1 },
                { image: Management, id: 2 },
                { image: OnlineStore, id: 3 },
              ],
            },
            {
              title: 'Front-end разработка',
              text: 'Создание логики и отображение готового дизайна - это важный процесс, который производит фронтенд разработчик. Мы внимательно продумываем каждый шаг, чтобы сделать ваш проект быстрым, логичным и приятным для пользования.',
              img: [
                { image: Bike, id: 4 },
                { image: CirageStore, id: 5 },
                { image: AutoPart, id: 6 },
              ],
            },
            {
              title: 'Middleware разработка',
              text: 'Веб-разработка – это кропотливая работа, которую необходимо выполнять по всем правилам, чтобы конечный результат был успешно реализован. ForDream — компания, которая поможет вам воплотить все ваши идеи и новинки в жизнь.',
              img: [
                { image: Health, id: 7 },
                { image: FinSystem, id: 8 },
                { image: ILXS, id: 9 },
                { image: Eldax, id: 10 },
                { image: Firewall, id: 11 },
              ],
            },
          ],
          technologies: {},
          popUp: {
            text: 'Благодарю вас! Наш менеджер свяжется с вами в ближайшее время!',
            btnConfirm: 'Подтвердить',
          },
          footer: {
            AboutTitle: 'О нас',
            ServiceTitle: 'Услуги',
            ConnectionTitle: 'Контакты',
            ContactTitle: 'Контакты',
            ContactText: 'телефон',
          },
          fitness: {
            title: 'Редизайн мобильного приложения по фитнесу',
            text: 'Идея проекта заключается в том, чтобы предложить пользователям индивидуальный комплекс упражнений исходя из их предпочтений, желаний, возможностей и целей.',
            functionTitle: 'Функции:',
            functionArray: [
              { text: 'завести аккаунт' },
              {
                text: 'заполнение анкеты для подбора комплекса',
              },
              {
                text: 'большой выбор различных упражнений и комплексов на любой вкус',
              },
              { text: 'платные комплексы упражнений' },
            ],
            problemTitle: 'Проблемы:',
            problemArray: [
              { text: 'неудобный функционал' },
              { text: 'неприятный визуал' },
              { text: 'отсутствие необходимых функций' },
            ],
            processTitle: 'Наш процесс:',
            processArr: [
              { text: 'интервью с клиентом' },
              { text: 'проведение UX-исследования, выявление проблем' },
              { text: 'добавление функций' },
              { text: 'интервью с потенциальными пользователями' },
              { text: 'описание user-flow' },
              { text: 'прототипирование' },
              { text: 'создание дизайн-системы/создание UI-китов' },
              { text: 'создание мокапов' },
            ],
            OutputTitle: 'Проект на выходе',
            outputArr: [
              { text: 'создание аккаунта' },
              {
                text: 'заполнение анкеты для подбора комплекса',
              },
              {
                text: 'большой выбор различных упражнений и комплексов на любой вкус',
              },
              { text: 'платные комплексы упражнений' },
              { text: 'просмотр ваших результатов' },
              {
                text: 'заполнение анкеты каждую неделю для отслеживания результатов',
              },
              { text: 'возможность взаимодействия с другими пользователями' },
              { text: 'возможность работать напрямую с тренером онлайн' },
              { text: 'получение бонусов за пройденные марафоны' },
            ],
          },
          management: {
            title: 'ВЕБ приложение по менеджменту',
            text: 'Идея проекта состоит в том, чтобы позволить пользователям вводить свои данные о своих проектах и задачах и просматривать их результаты, повышая свою производительность.',
            functionTitle: 'Функции:',
            functionArray: [
              { text: 'создание нескольких проектов' },
              { text: 'создание задач в проектах' },
              { text: 'таймер' },
              { text: 'графики активности' },
              {
                text: 'получение уведомления, когда таймер пропущен или задача не выполнена вовремя',
              },
              { text: 'отображение результатов каждую неделю/месяц/год' },
            ],
            taskTitle: 'Задачи:',
            taskArray: [
              { text: 'дизайн с нуля' },
              { text: 'версия веб-дизайна' },
              { text: 'исследование пользователей' },
            ],
            processTitle: 'Наш процесс',
            processArray: [
              { text: 'проведение брифинга с клиентом' },
              { text: 'проведение UX-исследования' },
              { text: 'установка приоритетов' },
              { text: 'постановка основных целей проекта' },
              { text: 'интервью с потенциальными пользователями' },
              { text: 'описание user-flow' },
              { text: 'прототипирование' },
              { text: 'создание дизайн-системы/создание UI-китов' },
              { text: 'создание мокапов' },
            ],
          },
          onlineStore: {
            title: 'Онлайн магазин косметики / Адаптивный дизайн',
            text: 'Идея проекта заключается в создании дизайна для веб и мобильной версий интернет-магазина по продаже натуральной косметики.',
            functionArray: [
              { text: 'возможность просмотра товаров' },
              {
                text: 'возможность находить товары с помощью поиска и фильтров',
              },
              { text: 'возможность заказать товар и оплатить через сайт' },
              {
                text: 'возможность обратной связи через онлайн-чат внутри сайта',
              },
              { text: 'регистрация аккаунта' },
              { text: 'получение скидок' },
              { text: 'совершение возврата или обмена через сайт' },
            ],
            taskArray: [
              { text: 'дизайн с нуля' },
              { text: 'веб версия' },
              { text: 'мобильная версия' },
              { text: 'исследование пользователей' },
            ],
            processArray: [
              { text: 'проведение брифинга с клиентом' },
              { text: 'проведение UX-исследования' },
              { text: 'установка приоритетов' },
              { text: 'постановка основных целей проекта' },
              { text: 'интервью с потенциальными пользователями' },
              { text: 'описание user-flow' },
              { text: 'прототипирование' },
              { text: 'создание дизайн-системы/создание UI-китов' },
              { text: 'создание мокапов' },
            ],
          },
          electricBike: {
            title: 'Онлайн магазин электро-велосипедов',
            text: 'Идея проекта заключается в том, что пользователю предоставляется возможность самостоятельно собрать собственный велосипед с помощью конструктора.',
            reqTitle: 'Требования:',
            reqArray: [
              { text: 'создать сайт пользователя и сайт администратора' },
              { text: 'создать список деталей' },
              { text: 'возможность покупки и оплаты онлайн' },
            ],
            impTitle: 'Реализация:',
            impArray: [
              { text: 'реализовать скролл с контейнером компонентов' },
              { text: 'реализовать сортировку запчастей' },
              { text: 'избегать конфликтов между компонентами' },
              {
                text: 'создать начальную сортировку по размеру/емкости аккумулятора',
              },
            ],
            technoTitle: 'Technologies',
            technoArray: [
              { text: 'React JS' },
              { text: 'Redux' },
              { text: 'PropTypes' },
              { text: 'CSS/SCSS' },
              { text: 'ESLint' },
              { text: 'Rest API' },
              { text: 'Airbnb' },
              { text: 'Parcel' },
            ],
          },
          cigar: {
            title: 'Онлайн магазин сигар',
            text: 'Идея сайта заключается в продаже сигар дорогих марок из разных стран.',
            reqTitle: 'Требования:',
            reqArray: [
              { text: 'выбор сигарет на любой вкус с помощью фильтров' },
              { text: 'выбор страны происхождения' },
              { text: 'выбор табака/размера' },
              { text: 'выбор подходящего алкоголя' },
            ],
            impTitle: 'Implementation:',
            impArray: [
              { text: 'создание фильтров по заданным параметрам' },
              { text: 'регистрация и оплата онлайн' },
            ],
            technoTitle: 'Технологии',
            technoArray: [
              { text: 'React JS' },
              { text: 'Redux Toolkit' },
              { text: 'TypeScript' },
              { text: 'StyledComponents' },
              { text: 'ESLint' },
              { text: 'Rest API' },
              { text: 'Airbnb' },
              { text: 'Docker' },
              { text: 'Material UI' },
              { text: 'Sentry' },
            ],
          },
          autoPart: {
            title: 'Магазин автозапчастей для автосалона',
            text: 'Идея проекта заключается в том, чтобы позволить пользователю выбирать запчасти для автомобиля, а также механика, который будет работать с его авто.',
            reqTitle: 'Требования:',
            reqArray: [
              { text: 'фильтр по марке автомобиля' },
              { text: 'подбор запчастей' },
              { text: 'выбор механика' },
              { text: 'общая база запчастей' },
              { text: 'оплата и регистрация на сайте' },
            ],
            impTitle: 'Реализация:',
            impArray: [
              {
                text: 'создание общей базы запчастей, где будут храниться данные пользователя и информация о замене его запчастей',
              },
              { text: 'сканирование номера и указание регистрации' },
              {
                text: 'база данных, в которой хранится вся информация о зарегистрированном авто',
              },
            ],
            technoTitle: 'Технологии',
            technoArray: [
              { text: 'React Native' },
              { text: 'RTK Query' },
              { text: 'TypeScript' },
              { text: 'ESLint' },
              { text: 'Push Notification' },
              { text: 'Localization' },
              { text: 'Google Maps' },
              { text: 'Camera' },
              { text: 'QR Scanner' },
              { text: 'Sentry' },
            ],
          },
          health: {
            title: 'Dfx.Api.Health-Checks',
            text: 'Подсистема проверки жизнеобеспечения сервисов системы обслуживания машин в США/Канаде на основе библиотеки обработки массовых сообщений NServiceBus.',
            technoArray: [
              { text: 'анализ структуры кода' },
              { text: 'глубокий рефакторинг' },
              { text: 'реализация модульного тестирования BDD' },
              { text: 'выделение чистого SDK' },
              {
                text: 'создание нормального прототипа использующего разработанный SDK',
              },
              { text: 'генерация технической документации' },
            ],
          },
          finSystem: {
            title: 'FinSys System',
            text: 'Финансовая система для обычных пользователей.',
            technoArray: [
              {
                text: 'разработка архитектуры, модульного фреймверка на основе ASP .NET4',
              },
              { text: 'Разработка слоя доступа к данным на основе EF6.0' },
              {
                text: 'Разработка программы развертывания первоначальной БД на основе EF6.0',
              },
              { text: 'Разработка центральных модулей фреймверка' },
              {
                text: 'Разработка механизма авторизации и аутентификации',
              },
              {
                text: 'Разработка и поддержка модуля системы мультиязычности',
              },
              {
                text: 'Разработка и поддержка модуля логгирования',
              },
              {
                text: 'Разработка Windows службы получения курсов валют с сайта www.xe.xom',
              },
              {
                text: 'Разработка Windows службы обслуживания финансовых транзакций',
              },
              {
                text: 'Разработка и поддержка модуля рассылки СМС',
              },
              {
                text: 'Разработка и поддержка модуля WebApi',
              },
              {
                text: 'Разработка и поддержка клиентской библиотеки для доступа к ядру системы из внешнего проекта, реализующего пользовательский интерфейс системы',
              },
              {
                text: 'Разработка и поддержка тестов для клиентской библиотеки доступа к ядру системы из внешнего проекта, реализующего пользовательский интерфейс системы',
              },
            ],
          },
          ILXSys: {
            title: 'ILX System',
            text: 'Финансовая система для валютных диллеров.',
            technoArray: [
              { text: 'Разработка архитектуры сайта' },
              { text: 'Разработка слоя доступа к данным на основе EF6.0' },
              { text: 'Разработка подсистемы WebAPI' },
              { text: 'Разработка интерфейса сайта' },
              {
                text: 'Разработка и поддержка клиентской библиотеки для доступа к ядру системы из внешнего проекта реализующего пользовательский интерфейс системы',
              },
              {
                text: 'Разработка и поддержка тестов для клиентской библиотеки доступа к ядру системы из внешнего проекта реализующего пользовательский интерфейс системы',
              },
            ],
          },
          archiveSys: {
            title: 'Eldax',
            text: 'Система электронного архива.',
            technoArray: [
              {
                text: 'Создание и поддержка общей архитектуры проекта',
              },
              {
                text: 'Создание и поддержка общей системы мультиязычности проекта',
              },
              {
                text: 'Создание и поддержка локальной системы nuget пакетов проекта',
              },
              {
                text: 'Создание и поддержка модуля авторизации и аутентификации проекта',
              },
              { text: 'Создание и поддержка модуля логгирования проекта' },
              {
                text: 'Создание и поддержка модуля “Storages” системы',
              },
              {
                text: 'Создание и поддержка модуля “Universal lookups”',
              },
              {
                text: 'Создание и поддержка раздела “Permissions”',
              },
            ],
          },
          firewall: {
            title: 'Великий китайский фаервол',
            text: 'Система обхода Золотого щита.',
            technoArray: [
              {
                text: 'Написание Helper Business Object на C#, для IE 8-11, клиентской части которая, взаимодействуя с серверной частью, отсылает статистику и получает правила редиректов для указанных заблокированных доменов',
              },
              {
                text: 'Серверная часть, ASP .NET MVC, написание подсистемы статистики, обработка 5 000 000 записей в базе MS SQL Server',
              },
              {
                text: 'Внесение изменений, новой функциональности, в плагин для Google Chrome аналог BHO для IE 8-11',
              },
              {
                text: 'Сбор статической информации с страниц посещаемых пользователем Google Chrome которая попадает под определенные правила и отправка данной статистики на сервер',
              },
              {
                text: 'Обработка статической информации, полученной от плагина для Google Chrome на сервере',
              },
              { text: 'Развертывание системы отчетов сервера MS SQL Server' },
              {
                text: 'Синхронизация баз данных, production & reporting для получения актуальных данных для создания отчетов',
              },
              {
                text: 'Серверная часть, ASP .NET MVC, написание подсистемы авторизации и аутентификации',
              },
              {
                text: 'Серверная часть, ASP .NET MVC, написание подсистемы определения страны, города, района на основе MaxMind DB GeoIp, выдача для клиентской части системы частных правил для определенной страны.',
              },
            ],
          },
          NDA: 'Согласно NDA мы не можем предоставить больше информации',
        },
      },
    },
    debug: false,
    detection: {
      order: ['localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
