//modules
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//components
import ChildCase from '../ChildCase'
//assets
import { images } from '../../../assets/image'
//interface
import { CaseScreenProps } from '../../../interfaces/CaseScreenProps'

const CigarShop = ({
  onClick,
  language,
  isMobileScreen,
  headerClick,
  isLaptopWidth,
}: CaseScreenProps) => {
  const { t } = useTranslation()

  const reqArray: string[] = t('cigar.reqArray', {
    returnObjects: true,
  })
  const impArray: string[] = t('cigar.impArray', {
    returnObjects: true,
  })
  const technoArray: string[] = t('cigar.technoArray', {
    returnObjects: true,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ChildCase
      isLaptopWidth={isLaptopWidth}
      headerClick={headerClick}
      title={t('cigar.title')}
      text={t('cigar.text')}
      functionArr={reqArray}
      taskArr={impArray}
      OutProjectArr={technoArray}
      image={isMobileScreen ? null : images.technoCaseImg}
      onClick={onClick}
      language={language}
      processTitle={t('cigar.technoTitle')}
      functionTitle={t('cigar.reqTitle')}
      taskTitle={t('electricBike.impTitle')}
      isMobileScreen={isMobileScreen}
    />
  )
}

export default CigarShop
