//modules
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// utils
import '../../utils/i18next'
//components
import SucessSvg from '../../assets/svg/sucessSvg'
//style
import './style.css'
// store
import { CheckFull, HandleChange } from '../../store/popUp/logic'
// constants
import { geolocationDB } from '../../constants'
//type
import { InputValueTypes } from '../../types/InputValueTypes'
//interface
import { ModalProps } from '../../interfaces/ModalProps'
import InputSvg from '../../assets/svg/InputSvg'

const Modal = ({ active, setActive }: ModalProps) => {
  const [inputValue, setInputValue] = useState<InputValueTypes>({
    name: '',
    email: '',
    aboutProject: '',
  })
  const [errName, setErrName] = useState<boolean>(false)
  const [errEmail, setErrEmail] = useState<boolean>(false)
  const [errValue, setErrValue] = useState<boolean>(false)
  const [details, setDetails] = useState<any>(null)
  const [show, setShow] = useState<boolean>(false)
  const [file, setFile] = useState<null>(null)
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')

  useEffect(() => {
    fetch(geolocationDB)
      .then((response) => response.json())
      .then((data) => setDetails(data))
      .catch((rejected) => {
        console.log('error !', rejected)
      })
  }, [])

  const handleChange = (e: any) => {
    HandleChange(e, setInputValue, inputValue)
  }

  const checkFull = () => {
    CheckFull(
      inputValue.email,
      inputValue.name,
      inputValue.aboutProject,
      setErrEmail,
      setErrName,
      setErrValue,
      details.country_name,
      details.city,
      details.IPv4,
      setShow,
      file
    )
  }

  return (
    <div
      className={active ? 'modal active' : 'modal'}
      onClick={() => setActive(false)}
    >
      <div
        className={
          active && language === 'en'
            ? 'modalContent active'
            : active && language === 'ru'
            ? 'modalContentRu active'
            : language === 'ru'
            ? 'modalContentRu'
            : 'modalContent'
        }
        onClick={(e) => e.stopPropagation()}
      >
        {!show ? (
          <div className={'modalTexts'}>
            <div className={'modalClose'}>
              <p onClick={() => setActive(false)}>×</p>
            </div>
            <div className={'inputContainer'}>
              <p className={'modalText'}>
                {/*@ts-ignore*/}
                {t('inputs.text')}
              </p>
              <form action="src/components/popUp/index" className={'forms'}>
                <input
                  name={'name'}
                  value={inputValue.name}
                  onChange={handleChange}
                  type="text"
                  className={
                    !errName
                      ? 'nameInput'
                      : inputValue.name.length > 1
                      ? 'nameInput'
                      : 'nameErr'
                  }
                  placeholder={t('inputs.name')}
                />
                <input
                  name={'email'}
                  value={inputValue.email}
                  type="email"
                  onChange={handleChange}
                  className={
                    !errEmail
                      ? 'nameInput'
                      : inputValue.email.length > 1
                      ? 'nameInput'
                      : 'nameErr'
                  }
                  placeholder={'Email'}
                />
                <textarea
                  className={
                    !errValue
                      ? 'textarea'
                      : inputValue.aboutProject.length > 1
                      ? 'textarea'
                      : 'areaErr'
                  }
                  name="aboutProject"
                  value={inputValue.aboutProject}
                  id="project"
                  onChange={handleChange}
                  cols={30}
                  rows={10}
                  placeholder={t('inputs.textarea')}
                ></textarea>
                <div className={'inputFileContainer'}>
                  <label className={'inputFile'}>
                    <input
                      type="file"
                      name={'file'}
                      onChange={(e: any) => setFile(e.target.files[0])}
                      accept={'.pdf, .doc'}
                    />
                    <InputSvg color={file ? '#1E34E1' : '#fff'} />
                    <span className={file ? 'TextInput' : ''}>Attach</span>
                  </label>
                </div>
                <button
                  type={'button'}
                  className={language === 'en' ? 'modalBtn' : 'modalBtnRu'}
                  onClick={checkFull}
                >
                  {t('popUp.btnConfirm')}
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div className={'sucessMassage'}>
            <div className={'modalCloseSucess'}>
              <p onClick={() => setActive(false)}>×</p>
            </div>
            <p>{t('popUp.text')}</p>
            <SucessSvg />
          </div>
        )}
      </div>
    </div>
  )
}

export default Modal
