//modules
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//components
import ChildCase from '../ChildCase'
//assets
import { images } from '../../../assets/image'
//interface
import { CaseScreenProps } from '../../../interfaces/CaseScreenProps'

const AutoPart = ({
  language,
  onClick,
  isMobileScreen,
  headerClick,
  isLaptopWidth,
}: CaseScreenProps) => {
  const { t } = useTranslation()

  const reqArray: string[] = t('autoPart.reqArray', {
    returnObjects: true,
  })
  const impArray: string[] = t('autoPart.impArray', {
    returnObjects: true,
  })
  const technoArray: string[] = t('autoPart.technoArray', {
    returnObjects: true,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ChildCase
      isLaptopWidth={isLaptopWidth}
      headerClick={headerClick}
      title={t('autoPart.title')}
      text={t('autoPart.text')}
      functionArr={reqArray}
      taskArr={impArray}
      OutProjectArr={technoArray}
      image={isMobileScreen ? null : images.technoCaseImg}
      onClick={onClick}
      language={language}
      functionTitle={t('cigar.reqTitle')}
      taskTitle={t('electricBike.impTitle')}
      isMobileScreen={isMobileScreen}
      processTitle={t('autoPart.technoTitle')}
    />
  )
}

export default AutoPart
