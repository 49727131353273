export const images = {
  groundFD: require('./backgroundFD.png'),
  TeamMember1: require('./TeamMember1.png'),
  TeamMember2: require('./TeamMember2.png'),
  TeamMember3: require('./TeamMember3.png'),
  TeamMember4: require('./TeamMember4.png'),
  TeamMember5: require('./TeamMember5.png'),
  Num1: require('./ApproachNum1.png'),
  Num2: require('./ApproachNum2.png'),
  Num3: require('./ApproachNum3.png'),
  Num4: require('./ApproachNum4.png'),
  imgFitness: require('./imgFitness.png'),
  managementImg: require('./managementImg.png'),
  storeImg: require('./storeImg.png'),
  technoCaseImg: require('./TechnoCaseImg.png'),
  healthImg: require('./healthImg.png'),
  FinSys: require('./FinSysImg.png'),
  ILX: require('./ILXImg.png'),
  Eldax: require('./EldaxImg.png'),
}
