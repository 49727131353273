import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function AboutArrows({ width, height, className }: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 20 40"
    >
      <path
        fill="url(#paint0_linear_2298_4914)"
        d="M3.318 0a2.858 2.858 0 012.23 1.057L19.35 18.2a2.856 2.856 0 010 3.628L5.062 38.967A2.861 2.861 0 01.66 35.312l12.774-15.313L1.089 4.685A2.856 2.856 0 013.32 0z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_2298_4914"
          x1="20"
          x2="-0.833"
          y1="20"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB"></stop>
          <stop offset="1" stopColor="#2033E0"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default AboutArrows

AboutArrows.defultProps = {
  width: 20,
  height: 40,
}
