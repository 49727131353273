// modules
import React from 'react'
import { motion } from 'framer-motion'
//interface
import { ProjectMobContentProps } from '../../../../interfaces/ProjectMobContentProps'
// components
import { Button } from '../../../../components/Button'
// utils
import { imgAnimation } from '../../../../utils/animation/data'
// assets
import { images } from '../../../../assets/image'

const ProjectMobContent = ({
  setInputScreen,
  btnText,
  ProjectTitle,
  isLaptopWidth,
  isBigLaptop,
  isLandscape,
  isMobileScreen,
}: ProjectMobContentProps) => {
  return (
    <div
      className={
        !isLaptopWidth && !isLandscape
          ? 'mobProjectContainer'
          : isLandscape
          ? 'LandProjectContainer'
          : 'lapProjectContainer'
      }
    >
      <span
        className={
          !isLaptopWidth && !isLandscape
            ? 'projectTextMob'
            : isBigLaptop && !isLandscape
            ? 'projectTextBLap'
            : isLandscape
            ? 'projectTextLand'
            : 'projectTextLap'
        }
      >
        {ProjectTitle}
      </span>
      <div className={!isLaptopWidth ? 'btnMob' : 'btnLap'}>
        <Button
          text={btnText}
          onClick={() => {
            setInputScreen(true)
          }}
          className={
            !isLaptopWidth && !isLandscape
              ? 'buttonHomeMob'
              : isLandscape
              ? 'buttonHomeLand'
              : 'buttonHomeLap'
          }
        />
      </div>
      <motion.div
        className={
          isMobileScreen && !isLaptopWidth
            ? 'imgProjectMob'
            : isMobileScreen && isLaptopWidth
            ? 'imgProjectLap'
            : 'imgContainer'
        }
        initial={'hidden'}
        whileInView={'visible'}
      >
        <div>
          <motion.img
            custom={1}
            variants={imgAnimation}
            src={images.groundFD}
            alt="pics"
            className={
              !isLaptopWidth && !isLandscape
                ? 'projectImgMob'
                : isLandscape
                ? 'projectImgLand'
                : 'projectImgLap'
            }
          />
        </div>
      </motion.div>
    </div>
  )
}

export default ProjectMobContent
