// modules
import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
// utils
import { textAnimation } from '../../../../utils/animation/data'
//interface
import { ScreenProps } from '../../../../interfaces/ScreenProps'
//styles
import '../../style.css'
import AboutArrows from '../../../../assets/svg/AboutArrows'
import { ArrowsArr } from '../../../../constants'

const AboutText = (props: ScreenProps) => {
  const { setActive, isMobileScreen, isLandscape } = props
  const { t } = useTranslation()
  return (
    <div
      className={
        isMobileScreen && isLandscape ? 'aboutContentLand' : 'aboutContent'
      }
    >
      <div>
        <div
          className={
            isMobileScreen && !isLandscape
              ? 'textContainerMob'
              : isMobileScreen && isLandscape
              ? 'textContainerLand'
              : 'textAboutContainer'
          }
        >
          <motion.p
            custom={1}
            variants={textAnimation}
            className={
              isMobileScreen && !isLandscape ? 'textAboutMob' : 'textAbout'
            }
          >
            {t('about.text')}
          </motion.p>
        </div>
        <div
          className={
            isMobileScreen && !isLandscape
              ? 'aboutArrowsContainerMob'
              : 'aboutArrowsContainer'
          }
          onClick={() => setActive(true)}
        >
          {ArrowsArr.map((item, index) => {
            return (
              <AboutArrows
                key={index}
                width={
                  isMobileScreen && !isLandscape ? item.mobWidth : item.width
                }
                height={
                  isMobileScreen && !isLandscape ? item.mobHeight : item.height
                }
                className={item.className}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AboutText
