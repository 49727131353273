//Modules
import React from 'react'
import { SvgProps } from '../../interfaces/SvgProps'

function LogoSvg(props: SvgProps) {
  const { width, height } = props
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 44 49">
      <path fill="#fff" d="M0 0h44v6H0V0z" />
      <path fill="#fff" d="M44 0v22h-6V0h6zM24 0v17h-6V0h6z" />
      <path
        fill="url(#paint0_linear_599_26473)"
        fillRule="evenodd"
        d="M.25 24.25h43.5V27c0 3.44-.664 8.828-3.83 13.433-3.287 4.783-8.996 8.317-18.183 8.317-8.718 0-14.263-3.587-17.517-8.316C1.08 35.868.25 30.51.25 27v-2.75zm5.7 5.5c.342 2.418 1.167 5.191 2.801 7.566 2.193 3.187 6.016 5.934 12.986 5.934 7.576 0 11.498-2.8 13.651-5.933 1.622-2.36 2.386-5.125 2.69-7.567H5.95z"
        clipRule="evenodd"
      />
      <path
        fill="url(#paint1_linear_599_26473)"
        d="M13 13.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z"
      />
      <defs>
        <linearGradient
          id="paint0_linear_599_26473"
          x1="0.25"
          x2="45.563"
          y1="36.5"
          y2="36.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_599_26473"
          x1="4"
          x2="13.375"
          y1="13.5"
          y2="13.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#560EAB" />
          <stop offset="1" stopColor="#2033E0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

LogoSvg.defaultProps = {
  width: 44,
  height: 49,
}

export default LogoSvg
