import React from 'react'
// @ts-ignore
import * as Scroll from 'react-scroll'
import { LinkImgProps } from '../../../../interfaces/LinkImgProps'

const LinkImg = ({
  path,
  onClick,
  img,
  isMobileScreen,
  isLandscape,
  isLaptopWidth,
  className,
}: LinkImgProps) => {
  let Link = Scroll.Link

  return (
    <Link
      to={path}
      smooth={true}
      onClick={onClick}
      className={
        isMobileScreen && !isLandscape && !isLaptopWidth
          ? 'imgLinkMob'
          : isMobileScreen && isLaptopWidth && !isLandscape
          ? 'imgLinkLap'
          : className
      }
    >
      <img
        src={img}
        alt="path"
        className={
          isMobileScreen && !isLandscape && !isLaptopWidth
            ? 'imgHomeMob'
            : isMobileScreen && isLaptopWidth && !isLandscape
            ? 'imgHomeLap'
            : 'imgHome'
        }
      />
    </Link>
  )
}

export default LinkImg
