export const HomeTextAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom: any) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
}

export const textAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: (custom: any) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
}

export const imgAnimation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom: any) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
}

export const svgAnimation = {
  hidden: {
    x: 50,
    opacity: 0,
  },
  visible: (custom: any) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2, duration: 0.5 },
  }),
}

export const SvgApproachAnim = {
  hidden: {
    x: 250,
    opacity: 0,
  },
  visible: (custom: any) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3, duration: 0.6 },
  }),
}

export const TextApproachAnim = {
  hidden: {
    x: -250,
    opacity: 0,
  },
  visible: (custom: any) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2, duration: 0.6 },
  }),
}

export const MainImgAnim = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom: any) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
}

export const lineAnimation = {
  hidden: {
    x: -300,
    opacity: 0,
  },
  visible: (custom: any) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2, duration: 0.5 },
  }),
}

export const AboutSlider = {
  hidden: {
    x: 500,
    opacity: 0,
  },
  visible: (custom: any) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2, duration: 0.7 },
  }),
}

export const lineAnimation2 = {
  hidden: {
    x: -400,
    opacity: 0,
  },
  visible: (custom: any) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2, duration: 0.5 },
  }),
}

export const lineAnimation3 = {
  hidden: {
    x: -1300,
    opacity: 0,
  },
  visible: (custom: any) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2, duration: 0.8 },
  }),
}
export const ArrowAnim = {
  hidden: {
    opacity: 0,
  },
  visible: () => ({
    opacity: 1,
    transition: { delay: 2, duration: 0.8 },
  }),
}

export const NaumAnimation = {
  hidden: {
    y: -150,
    opacity: 0,
  },
  visible: (custom: any) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
}
